type ProgressBarProps = {
  progress: number;
  color: 'klaq' | 'gray' | 'danger' | 'green' | 'warning';
};

type CircularProgressProps = {
  progress: number;
  color: 'klaq' | 'gray' | 'danger' | 'green' | 'warning';
  size: number;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const { progress, color } = props;

  if (progress < 0 || progress > 100) {
    throw new Error('Progress must be between 0 and 100');
  }

  return (
    <div className="overflow-hidden rounded-full bg-gray-200">
      <div
        className={`h-2 rounded-full bg-${color}-600`}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export const CircularProgress = (props: CircularProgressProps) => {
  const { progress, color, size } = props; // Added size prop
  const adjustedProgress = progress > 100 ? 100 : progress;
  const RADIUS = size / 2; // Adjusted size by multiplying with 0.9

  const adjustedR = RADIUS * 0.9; // Adjusted radius to be 90% of the original
  const adjustedCircumference = 2 * Math.PI * adjustedR;
  const adjustedDashOffset =
    adjustedCircumference - (adjustedProgress / 100) * adjustedCircumference;

  return (
    <div className={`flex items-center justify-center relative`}>
      <svg className="transform -rotate-90" width={size} height={size}>
        <circle
          cx={RADIUS}
          cy={RADIUS}
          r={adjustedR} // Use the adjusted radius here
          stroke="#e5e7eb"
          strokeWidth={`${adjustedR / 6}`} // Adjusted strokeWidth based on adjustedR
          fill="transparent"
          className="text-white-500"
        />
        <circle
          cx={RADIUS}
          cy={RADIUS}
          r={adjustedR} // Use the adjusted radius here
          stroke="currentColor"
          strokeWidth={`${adjustedR / 6}`} // Adjusted strokeWidth based on adjustedR
          fill="transparent"
          strokeLinecap="round"
          strokeDasharray={adjustedCircumference}
          strokeDashoffset={adjustedDashOffset}
          className={
            adjustedProgress < 20
              ? 'text-danger-500'
              : adjustedProgress < 40
                ? 'text-warning-500'
                : adjustedProgress < 60
                  ? 'text-yellow-300'
                  : adjustedProgress <= 100
                    ? 'text-success-500'
                    : `text-success-500`
          }
        />
      </svg>

      <span
        className={`absolute font-semibold text-${color}-600`}
        style={{ fontSize: `${size * 0.25}px` }}
      >
        {`${adjustedProgress}`}%
      </span>
    </div>
  );
};

// Default props
CircularProgress.defaultProps = {
  size: 90, // Default size
};
