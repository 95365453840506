import { DiscountType } from 'enum/discount-type.enum';
import { QuoteStatus } from 'enum/quote-status.enum';
import { Event } from 'interface/event/event.interface';
import { MainEvent } from 'interface/event/main-event.interface';
import { QuoteProduct } from 'interface/quote/quote-product.interface';
import { Quote } from 'interface/quote/quote.interface';

export const getProductSubtotal = (product: QuoteProduct) => {
  const discount =
    product.discountType === DiscountType.PERCENT
      ? Number(product.price) * (Number(product.discount) / 100)
      : product.discount;
  return Number(product.price) * product.quantity - Number(discount);
};

export const getSubtotalForQuote = (quote: Quote) => {
  const subtotal = quote.products.reduce((acc, curr) => {
    return acc + getProductSubtotal(curr);
  }, 0);
  return subtotal;
};

export const getQuotePipeValue = (events: Event[]) => {
  const pipeValue = events.map((event) => {
    if (event.quotes_ && event.quotes_.length > 0) {
      const quotes = event.quotes_.filter(
        (quote) => quote.status !== QuoteStatus.REJECTED,
      );

      const acceptedQuotes = quotes.filter(
        (quote) => quote.status === QuoteStatus.ACCEPTED,
      );
      if (acceptedQuotes.length)
        return acceptedQuotes
          .map((quote) => getSubtotalForQuote(quote))
          .reduce((acc, curr) => acc + curr);

      const quoteValues = quotes.map((quote) => {
        return getSubtotalForQuote(quote);
      });
      return Math.min(...quoteValues);
    }
    return 0;
  });

  const value = pipeValue.reduce((acc, curr) => acc + curr, 0);

  return value === Infinity ? '0.00' : value.toFixed(2);
};

export const getQuotePipeValueV2 = (events: MainEvent[]) => {
  if (!events) return '0.00';

  const pipeValue = events.map((event) => {
    if (event.quotes && event.quotes.length > 0) {
      const quotes = event.quotes.filter(
        (quote) => quote.status !== QuoteStatus.REJECTED,
      );

      const acceptedQuotes = quotes.filter(
        (quote) => quote.status === QuoteStatus.ACCEPTED,
      );
      if (acceptedQuotes.length)
        return acceptedQuotes
          .map((quote) => getSubtotalForQuote(quote))
          .reduce((acc, curr) => acc + curr);

      const quoteValues = quotes.map((quote) => {
        return getSubtotalForQuote(quote);
      });
      return Math.min(...quoteValues);
    }
    return 0;
  });

  const value = pipeValue.reduce((acc, curr) => acc + curr, 0);

  return value === Infinity ? '0.00' : value.toFixed(2);
};
