import {
  AlertWithLink,
  CardContainer,
  MailPopUp,
  Typography,
} from 'components';
import { differenceInDays, isPast, isToday } from 'date-fns';
import { Status } from 'enum/status.enum';
import { MainEvent } from 'interface/event/main-event.interface';
import { Invoice } from 'interface/invoice/invoice.interface';
import { InvoiceStatus } from 'enum/invoice-status.enum';
import { QuoteStatus } from 'enum/quote-status.enum';
import EditCustomer from 'pages/Customers/EditCustomer';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomerType } from 'enum/customer-type.enum';
import { getUser } from 'redux/Login/selectors';
import { useFetchMainEvents } from 'redux/MainEvent/hooks';
import { PATHS } from 'routes';

export const ActionSection = (event: MainEvent) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isEventPast = isPast(new Date(event.subEvents[0].date));
  const [shouldOpenEditCustomer, setOpenEditCustomer] = useState(false);
  const [, fetchEvents] = useFetchMainEvents();
  const [isMailPopUpOpened, setMailPopupOpen] = useState<boolean>(false);
  const [lateInvoice, setlateInvoice] = useState<Invoice | null>(null);
  const user = useSelector(getUser);

  type Action = {
    content: string;
    link: {
      text: string;
      onClick?: () => void;
      active: boolean;
    };
    status: Status;
  };

  const ACTIONS: Action[] = [];

  if (event.customer.type === CustomerType.COMPANY) {
    ACTIONS.push(
      !event.customer.legalRegistrationNumber
        ? {
            content: `Remplir les informations de la société`,
            link: {
              text: 'Remplir',
              onClick: () => setOpenEditCustomer(true),
              active: true,
            },
            status: Status.WARNING,
          }
        : {
            content: `Informations de la société complétées`,
            link: {
              text: 'Voir',
              onClick: () => setOpenEditCustomer(false),
              active: false,
            },
            status: Status.SUCCESS,
          },
    );
  } else {
    ACTIONS.push(
      !event.customer.address
        ? {
            content: `Remplir les informations de facturation du client (adresse, téléphone, email, etc.)`,
            link: {
              text: 'Remplir',
              onClick: () => setOpenEditCustomer(true),
              active: true,
            },
            status: Status.WARNING,
          }
        : {
            content: `Informations du client complétées`,
            link: {
              text: 'Voir',
              onClick: () => setOpenEditCustomer(false),
              active: false,
            },
            status: Status.SUCCESS,
          },
    );
  }

  ACTIONS.push({
    content: 'Créer un devis à partir de cet événement',
    link: {
      text: 'Générer',
      onClick: () =>
        navigate(`${PATHS.QUOTE_GENERATE}?fromEventId=${event.id}`),
      active:
        (event.products &&
          event.products.length &&
          event.quotes &&
          event.quotes.length > 0) ||
        isEventPast
          ? false
          : true,
    },
    status:
      (event.products &&
        event.products.length &&
        event.quotes &&
        event.quotes.length > 0) ||
      isEventPast
        ? Status.SUCCESS
        : Status.WARNING,
  });

  event.quotes &&
    event.quotes.map((quote) => {
      if (isEventPast) {
        return;
      }
      if (quote.status === QuoteStatus.DRAFT) {
        ACTIONS.push({
          content: `Devis ${quote.number} en attente de validation`,
          link: {
            text: 'Envoyer par mail',
            onClick: () => navigate(`${PATHS.QUOTE}/${quote.id}/send`),
            active: true,
          },
          status: Status.WARNING,
        });
      }
      if (quote.status === QuoteStatus.ACCEPTED) {
        ACTIONS.push({
          content: `Devis ${quote.number} accepté par le client`,
          link: {
            text: 'Voir le devis',
            onClick: () => navigate(`${PATHS.QUOTE}/${quote.id}/details`),
            active: true,
          },
          status: Status.SUCCESS,
        });
      }
      if (quote.status === QuoteStatus.REJECTED) {
        ACTIONS.push({
          content: `Devis ${quote.number} refusé par le client`,
          link: {
            text: 'Créer un nouveau devis',
            onClick: () =>
              navigate(`${PATHS.QUOTE_GENERATE}?fromEventId=${event.id}`),
            active: true,
          },
          status: Status.DANGER,
        });
      }
    });

  ACTIONS.push(
    !isEventPast || isToday(new Date(event.subEvents[0].date))
      ? {
          content: "L'événement n'a pas encore eu lieu",
          link: {
            text: 'Générer',
            active: false,
          },
          status: Status.PENDING,
        }
      : {
          content: "L'événement est terminé",
          link: {
            text: 'Générer',
            active: false,
          },
          status: Status.SUCCESS,
        },
  );

  event.quotes &&
    event.quotes.length > 0 &&
    event.quotes.map((quote) => {
      if (
        quote.status === QuoteStatus.ACCEPTED &&
        event.invoices &&
        event.invoices.length === 0
      ) {
        ACTIONS.push({
          content: `Créer une facture à partir du devis ${quote.number}`,
          link: {
            text: 'Générer',
            onClick: () =>
              navigate(`${PATHS.INVOICE_GENERATE}?fromQuote=${quote.id}`),
            active: true,
          },
          status: Status.WARNING,
        });
      }
    });

  event.invoices &&
    event.invoices.map((invoice) => {
      if (invoice.status === InvoiceStatus.DRAFT) {
        ACTIONS.push({
          content: `Facture ${invoice.number} en attente de validation`,
          link: {
            text: 'Finaliser ma facture',
            onClick: () => navigate(`${PATHS.INVOICE}/${invoice.id}/details`),
            active: true,
          },
          status: Status.WARNING,
        });
      }
      if (invoice.status === InvoiceStatus.PENDING) {
        ACTIONS.push({
          content: `Facture ${invoice.number} en attente de paiement`,
          link: {
            text: 'Voir la facture',
            onClick: () => navigate(`${PATHS.INVOICE}/${invoice.id}/details`),
            active: true,
          },
          status: Status.WARNING,
        });
      }
      if (invoice.status === InvoiceStatus.LATE) {
        ACTIONS.push({
          content: `Facture ${invoice.number} en retard`,
          link: {
            text: 'Envoyer un mail de relance',
            onClick: () => {
              setlateInvoice(invoice), setMailPopupOpen(true);
            },
            active: true,
          },
          status: Status.DANGER,
        });
      }
      if (invoice.status === InvoiceStatus.PAID) {
        ACTIONS.push({
          content: `Facture ${invoice.number} payée`,
          link: {
            text: 'Voir la facture',
            onClick: () => navigate(`${PATHS.INVOICE}/${invoice.id}/details`),
            active: true,
          },
          status: Status.SUCCESS,
        });
      }
    });

  useEffect(() => {
    fetchEvents();
  }, [shouldOpenEditCustomer]);

  return (
    <>
      <CardContainer className="p-4 w-full flex flex-col space-y-4">
        <Typography variant="h1" className="text-gray-900 font-semibold">
          {intl.formatMessage({
            id: 'event-details.actions.header',
          })}
        </Typography>
        {ACTIONS.map((action) => (
          <AlertWithLink key={action.content} {...action} />
        ))}
      </CardContainer>
      <EditCustomer
        customer={event.customer}
        isOpen={shouldOpenEditCustomer}
        setOpen={setOpenEditCustomer}
      />
      {lateInvoice && (
        <MailPopUp
          isOpen={isMailPopUpOpened}
          setOpen={setMailPopupOpen}
          content={{
            to: event.customer.email,
            subject: intl.formatMessage({
              id: `invoice-send.reminder.subject`,
            }),
            message: intl.formatMessage(
              {
                id: `invoice-send.reminder.message`,
              },
              {
                stageName: user.stageName,
                type: event.title.toLowerCase(),
                date: new Date(event.subEvents[0].date).toLocaleDateString(),
                emissionDate: new Date(
                  lateInvoice.issuedOn,
                ).toLocaleDateString(),
                invoiceNumber: lateInvoice.number,
                daysLate: differenceInDays(
                  new Date(),
                  new Date(lateInvoice.validUntil),
                ),
              },
            ),
          }}
        />
      )}
    </>
  );
};
