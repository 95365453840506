import { Typography } from 'components/Typography';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { EyeIcon } from '@heroicons/react/24/solid';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { NotificationEvents } from 'enum/notifications.enum';
import { Notification } from 'interface/notification/notification.interface';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'utils/utils';

type NotificationCardProps = {
  notification: Notification;
  shouldShowContent?: boolean;
};

export const NotificationCard: FC<NotificationCardProps> = (
  props: NotificationCardProps,
) => {
  const { notification, shouldShowContent = true } = props;
  const navigate = useNavigate();

  const ICON_NOTIFICATION: {
    [key in NotificationEvents]: React.ForwardRefExoticComponent<
      Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
        title?: string | undefined;
        titleId?: string | undefined;
      } & React.RefAttributes<SVGSVGElement>
    >;
  } = {
    [NotificationEvents.ENQUIRY]: InboxArrowDownIcon,
    [NotificationEvents.INVOICE_OPENED]: EyeIcon,
    [NotificationEvents.QUOTE_OPENED]: EyeIcon,
  };

  const IconComponent = ICON_NOTIFICATION[notification.notificationEvent];

  return (
    <div
      className={classNames(
        'flex space-x-4 w-full p-2',
        notification.link &&
          ' hover:bg-gray-100 hover:rounded-md hover:cursor-pointer',
      )}
      onClick={() => (notification.link ? navigate(notification.link) : {})}
    >
      <div className="mt-2">
        <Typography
          variant="subtitle"
          className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-klaq-500"
        >
          <IconComponent className="h-5 w-5 text-white" />
        </Typography>
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <Typography
          variant="h4"
          className="text-sm font-semibold text-gray-900"
        >
          {notification.title}
        </Typography>
        {shouldShowContent && (
          <div className="ring-1 ring-gray-900/5 p-2 shadow-sm rounded-lg w-full">
            <Typography variant="body" className="text-sm text-gray-500">
              {notification.content}
            </Typography>
          </div>
        )}
        <div className="flex items-center space-x-2 justify-between text-sm text-gray-500">
          <Typography variant="body">
            {format(new Date(notification.createdAt), 'eeee, HH:mm', {
              locale: fr,
            })}
          </Typography>
          <Typography variant="body">
            {format(new Date(notification.createdAt), 'dd MMMM yyyy', {
              locale: fr,
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};
