import {
  ChevronDownIcon,
  ChevronUpIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button, CardContainer, CircularProgress, Spinner } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/Login/selectors';
import { getAllProducts } from 'redux/Products/selectors';
import { getMainEvents } from 'redux/MainEvent/selectors';
import { useFetchBankAccountDetails } from 'redux/BankAccountDetails/hooks';
import { useFetchProductItems } from 'redux/Products/hooks';
import { useFetchUser } from 'redux/Login/hooks';
import { useFetchMainEvents } from 'redux/MainEvent/hooks';
import { PATHS } from 'routes';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type Mission = {
  name: string;
  content: string;
  isCompleted: boolean;
  link: string;
  loom: string;
};

export const GamificationCard = () => {
  const intl = useIntl();
  const [isMissionListVisible, setIsMissionListVisible] = useState(false);

  const [{ isLoading: isFetchingUser }, fetchUser] = useFetchUser();
  const user = useSelector(getUser);

  const [{ isLoading: isFetchingProduct }, fetchProducts] =
    useFetchProductItems();
  const productItems = useSelector(getAllProducts);

  const [{ isLoading: isFetchingEvent }, fetchMainEvents] =
    useFetchMainEvents();
  const events = useSelector(getMainEvents);

  const [{ data }, fetchBankAccountDetails] = useFetchBankAccountDetails();

  const toggleMissionList = () => {
    setIsMissionListVisible(!isMissionListVisible);
  };

  const iban = data?.accountIBAN;

  const missions: Mission[] = [
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.first-product.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.first-product.content`,
      }),
      link: PATHS.PRODUCTS,
      loom: 'https://www.loom.com/share/f2483daf428848ecb097aaf3f08b679e?sid=e4099855-71d0-420b-9bd1-a6d7059d532b',
      isCompleted: productItems.length !== 0,
    },
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.user-logo.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.user-logo.content`,
      }),
      link: PATHS.PROFILE,
      loom: 'https://www.loom.com/share/46c64b23b59649379c41d9f69be7f5f9?sid=98d4885d-6507-49a8-81d5-63425700660b',
      isCompleted: user.logoUrl !== null,
    },
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.general-information.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.general-information.content`,
      }),
      link: PATHS.PROFILE,
      loom: 'https://www.loom.com/share/105479e1360d4b60956a9452e422a2f3?sid=0cc3f265-75ad-4367-b0f4-6168e1e368bc',
      isCompleted: user.biography !== null || user.website !== null,
    },
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.first-event.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.first-event.content`,
      }),
      link: PATHS.EVENTS,
      loom: 'https://www.loom.com/share/eafa59ff33dc42b99de34f68660af510?sid=20abdeb0-3eb7-4980-ba23-5df5da8a4fc2',
      isCompleted: events.length !== 0,
    },
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.iban.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.iban.content`,
      }),
      link: PATHS.BANK_ACCOUNT,
      loom: 'https://www.loom.com/share/f357b4ed418d43b28494b8c283f38bdc?sid=5e5790a6-8f45-42c8-a134-91a1ebb78dc1',
      isCompleted: iban !== undefined && iban !== null && iban !== '',
    },
    {
      name: intl.formatMessage({
        id: `dashboard.gamification.mission.google.name`,
      }),
      content: intl.formatMessage({
        id: `dashboard.gamification.mission.google.content`,
      }),
      link: PATHS.INTEGRATIONS,
      loom: 'https://www.loom.com/share/fda823f4cd7f4e438f15e754c4be49dd?sid=1c86cfc6-318b-401b-8346-2aa22b528f30',
      isCompleted: user.isGoogleOAuthActivated,
    },
  ];

  const getNextMission = () => {
    return missions.find((mission) => !mission.isCompleted);
  };

  const nextMission = getNextMission();

  const completedMissionsCount = missions.filter(
    (mission) => mission.isCompleted,
  ).length;
  const totalMissions = missions.length;
  const progressPercentage = parseInt(
    ((completedMissionsCount / totalMissions) * 100).toFixed(0),
  );

  const orderedMissions = [...missions].sort((a, b) => {
    if (a.isCompleted && !b.isCompleted) {
      return 1;
    }
    if (!a.isCompleted && b.isCompleted) {
      return -1;
    }
    return 0;
  });

  const isLoading = isFetchingUser || isFetchingProduct || isFetchingEvent;

  useEffect(() => {
    fetchProducts();
    fetchUser();
    fetchMainEvents();
    fetchBankAccountDetails();
  }, []);

  return (
    <CardContainer className="flex px-4 py-5 sm:p-6 w-full">
      <div className="flex flex-col flex-grow">
        <div className="flex flex-row items-center flex-grow">
          <div className="mr-10">
            {isLoading ? (
              <Spinner size={'xl'} color={'primary'} />
            ) : (
              <CircularProgress
                color="gray"
                progress={progressPercentage}
                size={150}
              />
            )}
          </div>
          <div className="flex flex-col flex-grow mr-1 space-y-4">
            <div className="space-y-2">
              {progressPercentage === 100 ? (
                <>
                  <div className="text-xl font-bold text-gray-900">
                    {intl.formatMessage({
                      id: `dashboard.gamification.greeting.header`,
                    })}
                  </div>
                  <div className="text-sm">
                    {intl.formatMessage({
                      id: `dashboard.gamification.greeting.description`,
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-xl font-bold text-gray-900">
                    {intl.formatMessage({
                      id: `dashboard.gamification.welcome`,
                    })}
                  </div>
                  <div className="text-sm">
                    {intl.formatMessage({
                      id: `dashboard.gamification.explanation`,
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="flex items-center">
              {nextMission && (
                <MissionCard
                  name={nextMission.name}
                  content={nextMission.content}
                  link={nextMission.link}
                  loom={nextMission.loom}
                  isCompleted={nextMission.isCompleted}
                />
              )}
              {progressPercentage < 100 && (
                <button
                  type="button"
                  className="mt-4 ml-6 flex flex-none items-center justify-center p-1.5 text-gray-500 hover:text-gray-600"
                  onClick={toggleMissionList}
                >
                  {isMissionListVisible ? (
                    <ChevronUpIcon className="h-6 w-6" />
                  ) : (
                    <ChevronDownIcon className="h-6 w-6" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          {isMissionListVisible && (
            <div className="ml-48 mt-4 space-y-4">
              {orderedMissions
                .filter((mission) => mission.name !== nextMission?.name)
                .map((mission, index) => (
                  <div className="mr-16">
                    <MissionCard
                      key={index}
                      name={mission.name}
                      content={mission.content}
                      link={mission.link}
                      loom={mission.loom}
                      isCompleted={mission.isCompleted}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </CardContainer>
  );
};

const MissionCard = (props: Mission) => {
  const navigate = useNavigate();

  const intl = useIntl();

  const { name, content, isCompleted, link, loom } = props;

  return (
    <div className="flex-grow flex items-center justify-between mt-4 ring-1 ring-gray-300 pl-4 p-2 rounded-md">
      <div className="flex items-center">
        {isCompleted ? (
          <>
            <div>
              <CheckCircleIcon className="text-success-600 h-6 w-6" />
            </div>
            <div className="flex-col ml-4">
              <div className="font-semibold text-sm m-2 line-through">
                {name}
              </div>
              <div className="text-xs m-2">{content}</div>
            </div>
          </>
        ) : (
          <>
            <div>
              <CheckCircleIcon className="text-gray-400 h-6 w-6" />
            </div>
            <div className="flex-col ml-4">
              <div className="font-semibold text-sm m-2">{name}</div>
              <div className="text-xs m-2">{content}</div>
            </div>
          </>
        )}
      </div>
      <div className="flex m-2">
        {isCompleted ? (
          <div className="text-success-600 m-2">
            {intl.formatMessage({ id: `dashboard.gamification.completed` })}
          </div>
        ) : (
          <>
            <div className="flex mr-6">
              <button onClick={() => window.open(loom, '_blank')}>
                <QuestionMarkCircleIcon className="w-5 h-5" />
              </button>
            </div>
            <div>
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={() => navigate(link)}
              >
                {intl.formatMessage({
                  id: `dashboard.gamification.button`,
                })}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
