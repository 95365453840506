import { useAsyncCallback } from '@react-hooks-library/core';
import { KlaqToast } from 'utils/KlaqToast';
import webClient from 'utils/webclient';

export const useDownloadPublicRoadmap = () => {
  return useAsyncCallback(
    async (eventId: string | undefined, userId: string) => {
      if (!eventId) {
        KlaqToast('danger', 'missing-eventId');
        return;
      }

      try {
        const { data } = await webClient.get(
          `/roadmap-public/${eventId}/${userId}/pdf`,
          {
            responseType: 'blob',
          },
        );

        if (data.size === 0) {
          console.error('Received empty Blob');
          return;
        }

        const blob = new Blob([data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Feuille de route.pdf');

        document.body.appendChild(link);
        link.click();
        link.remove();

        return data;
      } catch (error: any) {
        KlaqToast('danger', 'roadmap-pdf-error');
        console.error('Error:', error);
      }
    },
  );
};

export const useSendRoadmapByEmail = () => {
  return useAsyncCallback(
    async (
      values: { message: string; to: string; subject: string; cc: boolean },
      userId: string,
      eventId: string,
    ) => {
      try {
        await webClient.post(
          `/roadmap-public/${eventId}/${userId}/send`,
          values,
        );
        KlaqToast('success', 'roadmap-sent');
      } catch (error: any) {
        KlaqToast('danger', 'roadmap-error');
        console.error('Error:', error);
      }
    },
  );
};
