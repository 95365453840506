import { useId } from 'react';

type CheckboxProps = {
  description?: string;
  label?: string;
  className?: string;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'id'>;

export const Checkbox = (props: CheckboxProps) => {
  const { description, label, className } = props;
  const id = useId();

  return (
    <div className={className}>
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id={id}
            type="checkbox"
            {...props}
            className="h-4 w-4 rounded border-gray-300 text-klaq-600 focus:ring-klaq-600"
          />
        </div>
        {description || label ? (
          <div className="ml-3 text-sm leading-6">
            {label && (
              <label className="font-medium text-gray-900">{label}</label>
            )}
            {description && <p className="text-gray-500">{description}</p>}
          </div>
        ) : null}
      </div>
    </div>
  );
};
