import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../states';

type EventsSliceType = [];

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<EventsSliceType>) => {
      state.events = action.payload;
    },
    setEvent: (state, action: PayloadAction<Event>) => {
      state.events = [...state.events, action.payload];
    },
  },
});

export const { setEvents, setEvent } = eventsSlice.actions;

export default eventsSlice.reducer;
