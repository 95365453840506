import { max, min } from 'date-fns';
import { MainEvent } from 'interface/event/main-event.interface';

export const findMinDateForEvents = (events: MainEvent[]) => {
  const allDates = events.flatMap((event) =>
    event.subEvents.flatMap((subEvent) => new Date(subEvent.date)),
  );

  return min(allDates);
};

export const findMaxDateForEvents = (events: MainEvent[]) => {
  const allDates = events.flatMap((event) =>
    event.subEvents.flatMap((subEvent) => new Date(subEvent.date)),
  );

  return max(allDates);
};
