export const KLAQ_COLORS = {
  50: '#f2f8fd',
  100: '#e5f1fb',
  200: '#c1e1f6',
  300: '#8bc9ee',
  400: '#4dade3',
  500: '#2693d1',
  600: '#1775b2',
  700: '#145d90',
  800: '#155077',
  900: '#174363',
  950: '#0f2b42',
};
