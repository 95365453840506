import { useAsyncCallback } from '@react-hooks-library/core';
import { useFetchUser } from 'redux/Login/hooks';
import { KlaqToast } from 'utils/KlaqToast';
import webClient from 'utils/webclient';

export const useGoogleGenerateOAuthUrl = () => {
  return useAsyncCallback(async () => {
    try {
      const res = await webClient.get('google/oauth/url');
      return res.data;
    } catch (error: any) {
      return undefined;
    }
  });
};

export const useGoogleDriveInitiation = () => {
  const [, fetchUser] = useFetchUser();
  return useAsyncCallback(async () => {
    try {
      const res = await webClient.post('google/drive/initiate');
      fetchUser();
      KlaqToast('success', 'google-drive-initiated');
      return res.data;
    } catch (error: any) {
      KlaqToast('danger', 'google-drive-initiated');
      console.error(error);
      return undefined;
    }
  });
};

export const useGoogleCalendarInitiation = () => {
  const [, fetchUser] = useFetchUser();
  return useAsyncCallback(async () => {
    try {
      const res = await webClient.post('google/calendar/initiate');
      fetchUser();
      KlaqToast('success', 'google-calendar-initiated');
      return res.data;
    } catch (error: any) {
      KlaqToast('danger', 'google-calendar-initiated');
      console.error(error);
      return undefined;
    }
  });
};
