import * as Yup from 'yup';

export const settingsInitialValues = {
  isChangelogNotificationEnabled: true,
  isNewDealNotificationEnabled: true,
  isMarketingNotificationEnabled: true,
};

export const validationSettingsSchema = Yup.object({
  isChangelogNotificationEnabled: Yup.boolean(),
  isNewDealNotificationEnabled: Yup.boolean(),
  isMarketingNotificationEnabled: Yup.boolean(),
});
