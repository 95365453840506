export enum Timezone {
  GMTmin11 = '(GMT-11:00)',
  GMTmin10 = '(GMT-10:00)',
  GMTmin9_30 = '(GMT-09:30)',
  GMTmin9 = '(GMT-09:00)',
  GMTmin8 = '(GMT-08:00)',
  GMTmin7 = '(GMT-07:00)',
  GMTmin6 = '(GMT-06:00)',
  GMTmin5 = '(GMT-05:00)',
  GMTmin4 = '(GMT-04:00)',
  GMTmin3 = '(GMT-03:00)',
  GMTmin2_30 = '(GMT-02:30)',
  GMTmin2 = '(GMT-02:00)',
  GMTmin1 = '(GMT-01:00)',
  GMT = '(GMT-00:00)',
  GMTplus1 = '(GMT+01:00)',
  GMTplus2 = '(GMT+02:00)',
  GMTplus3 = '(GMT+03:00)',
  GMTplus3_30 = '(GMT+03:30)',
  GMTplus4 = '(GMT+04:00)',
  GMTplus4_30 = '(GMT+04:30)',
  GMTplus5 = '(GMT+05:00)',
  GMTplus5_30 = '(GMT+05:30)',
  GMTplus5_45 = '(GMT+05:45)',
  GMTplus6 = '(GMT+06:00)',
  GMTplus6_30 = '(GMT+06:30)',
  GMTplus7 = '(GMT+07:00)',
  GMTplus8 = '(GMT+08:00)',
  GMTplus8_45 = '(GMT+08:45)',
  GMTplus9 = '(GMT+09:00)',
  GMTplus9_30 = '(GMT+09:30)',
  GMTplus10 = '(GMT+10:00)',
  GMTplus10_30 = '(GMT+10:30)',
  GMTplus11 = '(GMT+11:00)',
  GMTplus12 = '(GMT+12:00)',
  GMTplus12_45 = '(GMT+12:45)',
  GMTplus13 = '(GMT+13:00)',
  GMTplus14 = '(GMT+14:00)',
}
