import {
  BuildingLibraryIcon,
  HomeIcon,
  InformationCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Button, SearchCompany, TextArea, TextField } from 'components';
import SidePanel from 'components/SidePanel';
import { Typography } from 'components/Typography';
import { useFormik } from 'formik';
import { CompanySuggestion } from 'interface/company/company-suggestion.interface';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { KlaqToast } from 'utils/KlaqToast';
import { useUpdateCustomer } from '../../redux/Customer/hooks';
import { Customer } from 'interface/customer/customer.interface';
import { CustomerType } from 'enum/customer-type.enum';
import { initialValues, validationSchema } from './form';
import { format } from 'date-fns';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  customer?: Customer;
  actionAfter?: () => void;
};

export const EditCustomer: FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { isOpen, setOpen, customer, actionAfter } = props;
  const [customerCompany, setCustomerCompany] = useState<
    CompanySuggestion | undefined
  >();

  const [{ isLoading }, updateCustomer] = useUpdateCustomer(actionAfter);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...customer,
      ...customerCompany,
      type: customerCompany?.legalRegistrationNumber
        ? CustomerType.COMPANY
        : CustomerType.PRIVATE,
      name:
        CustomerType.COMPANY && customerCompany
          ? customerCompany.legalName
          : customer?.name,
      birthdayDate: customer?.birthdayDate
        ? format(new Date(customer.birthdayDate), 'yyyy-MM-dd')
        : '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.type === CustomerType.PRIVATE)
        values.name = `${values.firstName} ${values.lastName}`;
      if (!values.phone && !values.email) {
        KlaqToast('danger', 'customer-phone-or-mail-required-error', 1500);
        return;
      }
      if (!values.phone || !values.email) {
        if (!values.phone && customer?.phone) {
          values.phone = customer.phone;
        }

        if (!values.email && customer?.email) {
          values.email = customer.email;
        }
        KlaqToast('danger', 'toast.error.user-update-failed', 1500);
        setOpen(false);
        resetForm();
      } else {
        await updateCustomer(values, customer?.id);
        setOpen(false);
        resetForm();
      }
    },
    enableReinitialize: true,
  });

  const handleSetCompany = (customerType: CustomerType) => {
    formik.setValues({ ...formik.values, type: customerType }, false);
  };

  return (
    <SidePanel
      isOpen={isOpen}
      setOpen={setOpen}
      titleId={'customers.edit-customer.header'}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col space-y-4">
          <div>
            <label
              htmlFor="client-type"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              {intl.formatMessage({
                id: 'customers.new-customer.label.client-type',
              })}
            </label>
            <div className="mt-2 flex flex-row space-x-4">
              <Button
                variant="contained"
                color={
                  formik.values.type === CustomerType.COMPANY
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() => handleSetCompany(CustomerType.COMPANY)}
                type="button"
                leadingIcon={
                  <BuildingLibraryIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                }
              >
                {intl.formatMessage({
                  id: 'customers.new-customer.input.client-type.company',
                })}
              </Button>
              <Button
                variant="contained"
                color={
                  formik.values.type === CustomerType.PRIVATE
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() => handleSetCompany(CustomerType.PRIVATE)}
                type="button"
                leadingIcon={
                  <UserIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                }
              >
                {intl.formatMessage({
                  id: 'customers.new-customer.input.client-type.private',
                })}
              </Button>
            </div>
          </div>
          {formik.values.type === CustomerType.COMPANY ? (
            <>
              <div>
                <label
                  htmlFor="look-for-company"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {intl.formatMessage({
                    id: 'customers.new-customer.label.look-for-company',
                  })}
                </label>
                <div className="mt-2">
                  <SearchCompany
                    customerCompany={customerCompany}
                    setCustomerCompany={setCustomerCompany}
                    placeholder={intl.formatMessage({
                      id: 'onboarding.search-company.input',
                    })}
                  />
                </div>
              </div>

              {/** divider */}
              <div className="mt-4">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <Typography
                      variant="subtitle"
                      className="bg-white px-2 text-gray-500"
                    >
                      <BuildingLibraryIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                      />
                    </Typography>
                  </div>
                </div>
              </div>
              {/** name */}
              <div>
                <div className="mt-2">
                  <TextField
                    label={intl.formatMessage({
                      id: `customers.new-customer.label.name`,
                    })}
                    placeholder={intl.formatMessage({
                      id: `customers.new-customer.input.name`,
                    })}
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />

                  {formik.errors.name && formik.touched.name ? (
                    <Typography
                      variant="body"
                      className="mt-2 text-sm text-danger-600"
                    >
                      {intl.formatMessage({
                        id: `customers.new-customer.error.name`,
                      })}
                    </Typography>
                  ) : null}
                </div>
              </div>
              {/** legalRegistrationNumber */}
              <div>
                <div className="mt-2">
                  <TextField
                    label={intl.formatMessage({
                      id: 'customers.new-customer.label.siret',
                    })}
                    placeholder={intl.formatMessage({
                      id: `customers.new-customer.input.siret`,
                    })}
                    name="legalRegistrationNumber"
                    onChange={formik.handleChange}
                    value={formik.values.legalRegistrationNumber}
                  />
                  {formik.errors.legalRegistrationNumber &&
                  formik.touched.legalRegistrationNumber ? (
                    <Typography
                      variant="body"
                      className="mt-2 text-sm text-danger-600"
                    >
                      {intl.formatMessage({
                        id: `customers.new-customer.error.siret`,
                      })}
                    </Typography>
                  ) : null}
                </div>
              </div>
              {/** VAT */}
              <div>
                <div className="mt-2">
                  <TextField
                    label={intl.formatMessage({
                      id: 'customers.new-customer.label.vat-number',
                    })}
                    placeholder={intl.formatMessage({
                      id: `customers.new-customer.input.vat-number`,
                    })}
                    name="legalVATNumber"
                    onChange={formik.handleChange}
                    value={formik.values.legalVATNumber}
                  />

                  {formik.errors.legalVATNumber &&
                  formik.touched.legalVATNumber ? (
                    <Typography
                      variant="body"
                      className="mt-2 text-sm text-danger-600"
                    >
                      {intl.formatMessage({
                        id: `customers.new-customer.error.vat-number`,
                      })}
                    </Typography>
                  ) : null}
                </div>
              </div>
              {/** Headcount */}
              <div>
                <div className="mt-2">
                  <TextField
                    type="text"
                    label={intl.formatMessage({
                      id: 'customers.new-customer.label.headcount',
                    })}
                    placeholder={intl.formatMessage({
                      id: `customers.new-customer.input.headcount`,
                    })}
                    name="headcount"
                    onChange={formik.handleChange}
                    value={formik.values.headcount}
                  />

                  {formik.errors.headcount && formik.touched.headcount ? (
                    <Typography
                      variant="body"
                      className="mt-2 text-sm text-danger-600"
                    >
                      {intl.formatMessage({
                        id: `customers.new-customer.error.headcount`,
                      })}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}

          {/** divider */}
          <div>
            <div className="mt-4 relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <Typography
                  variant="subtitle"
                  className="bg-white px-2 text-gray-500"
                >
                  <UserIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/** firstname */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.first-name',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.first-name`,
                })}
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />

              {formik.errors.firstName && formik.touched.firstName ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.first-name`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** lastname */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.last-name',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.last-name`,
                })}
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />

              {formik.errors.lastName && formik.touched.lastName ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.last-name`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** email */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.email',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.email`,
                })}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.email`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** phone */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.phone',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.phone`,
                })}
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {formik.errors.phone && formik.touched.phone ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.phone`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** phoneLandline */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.phone-landline',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.phone-landline`,
                })}
                name="phoneLandline"
                onChange={formik.handleChange}
                value={formik.values.phoneLandline}
              />
            </div>
          </div>
          <div>
            <Typography variant="body" className="mt-4 text-xs">
              {intl.formatMessage({
                id: 'customers.new-customer.informations.mandatory-fields.description-one',
              })}
            </Typography>
            <Typography variant="body" className="text-xs">
              {intl.formatMessage({
                id: 'customers.new-customer.informations.mandatory-fields.description-two',
              })}
            </Typography>
          </div>
          {/** divider */}
          <div>
            <div className="mt-4 relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <Typography
                  variant="subtitle"
                  className="bg-white px-2 text-gray-500"
                >
                  <HomeIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/** address */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.address',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.address`,
                })}
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />

              {formik.errors.address && formik.touched.address ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.address`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** city */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.city',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.city`,
                })}
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />

              {formik.errors.city && formik.touched.city ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.city`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** zipcode */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.zip',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.zip`,
                })}
                name="zipcode"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
              />

              {formik.errors.zipcode && formik.touched.zipcode ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.zip`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** country */}
          <div>
            <div className="mt-2">
              <TextField
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.country',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.country`,
                })}
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
              />

              {formik.errors.country && formik.touched.country ? (
                <Typography
                  variant="body"
                  className="mt-2 text-sm text-danger-600"
                >
                  {intl.formatMessage({
                    id: `customers.new-customer.error.country`,
                  })}
                </Typography>
              ) : null}
            </div>
          </div>
          {/** divider */}
          <div>
            <div className="mt-4 relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center">
                <Typography
                  variant="subtitle"
                  className="bg-white px-2 text-gray-500"
                >
                  <InformationCircleIcon
                    className="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </Typography>
              </div>
            </div>
          </div>
          {/** birthday */}
          <div>
            <div className="mt-2">
              <TextField
                type="date"
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.birthday',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.birthday`,
                })}
                name="birthdayDate"
                value={formik.values.birthdayDate}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {/** notes */}
          <div>
            <div className="mt-2">
              <TextArea
                label={intl.formatMessage({
                  id: 'customers.new-customer.label.notes',
                })}
                placeholder={intl.formatMessage({
                  id: `customers.new-customer.input.notes`,
                })}
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              isLoading={isLoading}
            >
              {intl.formatMessage({
                id: `customers.edit-customer.submit`,
              })}
            </Button>
          </div>
        </div>
      </form>
    </SidePanel>
  );
};

export default EditCustomer;
