import { RootState } from '../store';
import { ProductItem } from 'interface/product-item.interface';

export const getAllProducts = (state: RootState): ProductItem[] =>
  state.productItems.productItems;

export const getProductById = (
  state: RootState,
  id?: string,
): ProductItem | undefined => {
  if (!id) {
    return undefined;
  }
  return state.productItems.productItems.find(
    (productItem: ProductItem) => productItem.id === id,
  );
};
