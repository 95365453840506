import * as Yup from 'yup';
import { CompanyLegalForm } from 'enum/company-legal-form.enum';

export const initialCompanyFormValues = {
  activityType: '',
  inseeLegalFormCode: '',
  legalForm: CompanyLegalForm.SAS,
  legalName: '',
  legalRegistrationNumber: '',
  legalVATNumber: '',
  registrationDate: '',
  address: '',
  city: '',
  zip: '',
  tradeName: '',
  country: '',
};

export const companyFormSchema = Yup.object().shape({
  legalForm: Yup.string().required('Ce champ est requis'),
  legalName: Yup.string().required('Ce champ est requis'),
  legalRegistrationNumber: Yup.string().required('Ce champ est requis'),
  legalVATNumber: Yup.string(),
  address: Yup.string().required('Ce champ est requis'),
  city: Yup.string().required('Ce champ est requis'),
  zip: Yup.string().required('Ce champ est requis'),
  country: Yup.string().required('Ce champ est requis'),
});
