import { useAsyncCallback } from '@react-hooks-library/core';
import { ToastNotification } from 'components';
import { BillingCoverType } from 'enum/cover-type.enum';
import { Settings } from 'interface/user/settings.interface';
import toast from 'react-hot-toast';
import { KlaqToast } from 'utils/KlaqToast';
import webClient from 'utils/webclient';

export const useUpdateBillingCover = () => {
  return useAsyncCallback(async (type: BillingCoverType, file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);
      await webClient.post('/settings/billing/cover', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      KlaqToast('success', 'upload-cover');
    } catch (error: any) {
      const code = error.response.data.code
        ? error.response.data.code.toLowerCase()
        : 'default';
      KlaqToast('danger', `toast.error.${code}.title`);
      console.error(error);
      return error.response;
    }
  });
};

export const useDeleteBillingCover = () => {
  return useAsyncCallback(async (type: BillingCoverType) => {
    try {
      await webClient.delete('/settings/billing/cover', { data: { type } });
      KlaqToast('success', 'delete-cover');
    } catch (error: any) {
      const code = error.response.data.code
        ? error.response.data.code.toLowerCase()
        : 'default';
      KlaqToast('danger', `toast.error.${code}.title`);
      console.error(error);
      return error.response;
    }
  });
};

export const useUpdateSettings = () => {
  return useAsyncCallback(async (values: Partial<Settings>) => {
    try {
      await webClient.put('/settings', values);
      toast.custom(
        <ToastNotification
          status="success"
          titleId={`toast.success.update-preference.title`}
          messageId={`toast.success.update-preference.message`}
        />,
        { duration: 1500, position: 'top-right' },
      );
    } catch (error: any) {
      const code = error.response.data.code
        ? error.response.data.code.toLowerCase()
        : 'default';
      toast.custom(
        <ToastNotification
          status="danger"
          titleId={`toast.error.${code}.title`}
          messageId={`toast.error.${code}.message`}
        />,
        { duration: 1500, position: 'top-right' },
      );
      console.error(error);
      return error.response;
    }
  });
};
