import { Dialog, Disclosure, Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  DocumentCheckIcon,
  FolderIcon,
  HomeIcon,
  LifebuoyIcon,
  ShoppingBagIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFetchUser, useSignout } from 'redux/Login/hooks';
import { handleClickHelp } from 'utils/clickOnCrisp';
import KlaqLogo from '../../assets/logo-pres.png';
import { PATHS } from '../../routes';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type MobileSidebarProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const MobileSidebar = (props: MobileSidebarProps) => {
  const { isOpen, setOpen } = props;
  const intl = useIntl();

  const currentPage = `/${window.location.pathname.split('/')[1]}`;

  const navigation = [
    {
      name: 'sidebar.dashboard',
      href: PATHS.DASHBOARD,
      icon: HomeIcon,
      current: currentPage === PATHS.DASHBOARD,
    },

    {
      name: 'sidebar.events',
      href: PATHS.EVENTS,
      icon: FolderIcon,
      current: currentPage === PATHS.EVENTS,
    },
    {
      name: 'sidebar.customers',
      href: PATHS.CUSTOMERS,
      icon: UsersIcon,
      current: currentPage === PATHS.CUSTOMERS,
    },
    {
      name: 'sidebar.quotes',
      icon: ClipboardDocumentIcon,
      href: PATHS.QUOTES,
      current: currentPage === PATHS.QUOTES || currentPage === PATHS.QUOTE,
    },
    {
      name: 'sidebar.invoices',
      href: PATHS.INVOICES,
      icon: DocumentCheckIcon,
      current: currentPage === PATHS.INVOICES || currentPage === PATHS.INVOICE,
    },
    {
      name: 'sidebar.products',
      href: PATHS.PRODUCTS,
      icon: ShoppingBagIcon,
      current: currentPage === PATHS.PRODUCTS,
    },
    // {
    //   name: "sidebar.marketing",
    //   href: "#",
    //   icon: PresentationChartLineIcon,
    //   current: false,
    // },
    // {
    //   name: "sidebar.analytics",
    //   href: "#",
    //   icon: ChartPieIcon,
    //   current: false,
    // },
  ];

  const [, fetchUser] = useFetchUser();

  const [, logout] = useSignout();

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-klaq-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img src={KlaqLogo} className="mt-6 w-4/5" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-6">
                        <li key="sidebar">
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item: any) =>
                              item.submenu ? (
                                <Disclosure
                                  defaultOpen={item.submenu.some(
                                    (subitem: any) => subitem.current,
                                  )}
                                  as="div"
                                >
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-klaq-200 hover:text-white hover:bg-klaq-700">
                                        <item.icon
                                          className={classNames(
                                            item.current
                                              ? 'text-white'
                                              : 'text-klaq-200 group-hover:text-white',
                                            'h-6 w-6 shrink-0',
                                          )}
                                          aria-hidden="true"
                                        />
                                        {intl.formatMessage({
                                          id: item.name,
                                        })}
                                        {open ? (
                                          <ChevronUpIcon
                                            className="ml-auto h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ChevronDownIcon
                                            className="ml-auto h-6 w-6 shrink-0"
                                            aria-hidden="true"
                                          />
                                        )}
                                      </Disclosure.Button>
                                      <Disclosure.Panel
                                        as="ul"
                                        className="flex flex-col gap-y-1 mx-9"
                                      >
                                        {item.submenu.map((subitem: any) => (
                                          <li key={subitem.name}>
                                            <a
                                              href={subitem.href}
                                              className={classNames(
                                                subitem.current
                                                  ? 'bg-klaq-700 text-white'
                                                  : 'text-klaq-200 hover:text-white hover:bg-klaq-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                              )}
                                            >
                                              {intl.formatMessage({
                                                id: subitem.name,
                                              })}
                                            </a>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              ) : (
                                <li key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.current
                                        ? 'bg-klaq-700 text-white'
                                        : 'text-klaq-200 hover:text-white hover:bg-klaq-700',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? 'text-white'
                                          : 'text-klaq-200 group-hover:text-white',
                                        'h-6 w-6 shrink-0',
                                      )}
                                      aria-hidden="true"
                                    />
                                    {intl.formatMessage({
                                      id: item.name,
                                    })}
                                  </a>
                                </li>
                              ),
                            )}
                          </ul>
                        </li>

                        <li className="mt-auto" key="sidebar-footer">
                          <a
                            href={PATHS.SETTINGS}
                            className={classNames(
                              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-klaq-200 hover:bg-klaq-700 hover:text-white',
                              currentPage === PATHS.SETTINGS
                                ? 'bg-klaq-700 text-white'
                                : '',
                            )}
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-klaq-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            {intl.formatMessage({
                              id: 'sidebar.settings',
                            })}
                          </a>
                          <a
                            onClick={handleClickHelp}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-klaq-200 hover:bg-klaq-700 hover:text-white mt-1"
                          >
                            <LifebuoyIcon
                              className="h-6 w-6 shrink-0 text-klaq-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            {intl.formatMessage({
                              id: 'sidebar.help',
                            })}
                          </a>

                          <a
                            href={PATHS.LOGIN}
                            onClick={handleLogout}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-klaq-200 hover:bg-klaq-700 hover:text-white"
                          >
                            <ArrowRightOnRectangleIcon
                              className="h-6 w-6 shrink-0 text-klaq-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            {intl.formatMessage({ id: 'navbar.logout' })}
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};
