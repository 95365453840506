import {
  AdjustmentsVerticalIcon,
  ArrowsPointingInIcon,
  BanknotesIcon,
  BellAlertIcon,
  BuildingLibraryIcon,
  CreditCardIcon,
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { PATHS } from 'routes';
import { classNames } from 'utils/utils';
import { PageLayout } from '../../layouts';

export const Settings = () => {
  const intl = useIntl();

  const actions = [
    {
      title: intl.formatMessage({ id: 'navbar.title.profile' }),
      href: PATHS.PROFILE,
      icon: UserIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({ id: 'navbar.description.profile' }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.company' }),
      href: PATHS.COMPANY,
      icon: UsersIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({ id: 'navbar.description.company' }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.bank-account' }),
      href: PATHS.BANK_ACCOUNT,
      icon: BuildingLibraryIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({
        id: 'navbar.description.bank-account',
      }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.integrations' }),
      href: PATHS.INTEGRATIONS,
      icon: ArrowsPointingInIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({
        id: 'navbar.description.integrations',
      }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.subscriptions' }),
      href: PATHS.SUBSCRIPTION,
      icon: CreditCardIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({
        id: 'navbar.description.subscriptions',
      }),
    },

    {
      title: intl.formatMessage({ id: 'navbar.title.billing' }),
      href: PATHS.BILLING_SETTINGS,
      icon: BanknotesIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({ id: 'navbar.description.billing' }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.email-templates' }),
      href: '#',
      icon: EnvelopeIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({
        id: 'navbar.description.email-templates',
      }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.preference' }),
      href: PATHS.PREFERENCES,
      icon: AdjustmentsVerticalIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({ id: 'navbar.description.preference' }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.notifications' }),
      href: PATHS.NOTIFICATIONS,
      icon: BellAlertIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({
        id: 'navbar.description.notifications',
      }),
    },
    {
      title: intl.formatMessage({ id: 'navbar.title.security' }),
      href: PATHS.SECURITY,
      icon: LockClosedIcon,
      iconForeground: 'text-klaq-500',
      iconBackground: 'bg-klaq-50',
      description: intl.formatMessage({ id: 'navbar.description.security' }),
    },
  ];

  return (
    <PageLayout>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0
                ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1
                ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                : '',
              'group relative bg-white p-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-klaq-500',
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white',
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <a href={action.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.description}</p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-klaq-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};
