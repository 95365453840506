import { MainEvent } from 'interface/event/main-event.interface';
import { EventStatus } from 'enum/event-status.enum';

export const sortEventsByStatus = (
  events: MainEvent[],
  ...statusOrder: EventStatus[]
): MainEvent[] => {
  return events.sort((a, b) => {
    const dateA = new Date(a.subEvents[0].date);
    const dateB = new Date(b.subEvents[0].date);

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    const statusA = statusOrder.indexOf(a.status);
    const statusB = statusOrder.indexOf(b.status);

    return statusA - statusB;
  });
};

export const sortPendingEvents = (events: any[]) => {
  return sortEventsByStatus(
    events,
    EventStatus.QUALIFICATION,
    EventStatus.QUOTE_REJECTED,
    EventStatus.QUOTE_SENT,
  );
};

export const sortEvents = (events: any[]) => {
  return sortEventsByStatus(
    events,
    EventStatus.INBOX,
    EventStatus.INVOICE_OVERDUE,
    EventStatus.QUALIFICATION,
    EventStatus.QUOTE_REJECTED,
    EventStatus.QUOTE_ACCEPTED,
    EventStatus.QUOTE_SENT,
    EventStatus.INVOICE_SENT,
    EventStatus.DONE,
    EventStatus.READY,
    EventStatus.WIN,
    EventStatus.LOST,
  );
};

export const sortNewEvents = (events: any[]) => {
  return sortEventsByStatus(events, EventStatus.INBOX);
};

export const sortReadyEvents = (events: any[]) => {
  return sortEventsByStatus(
    events,
    EventStatus.QUOTE_ACCEPTED,
    EventStatus.INVOICE_SENT,
    EventStatus.READY,
  );
};

export const sortDoneEvents = (events: any[]) => {
  return sortEventsByStatus(
    events,
    EventStatus.INVOICE_OVERDUE,
    EventStatus.DONE,
    EventStatus.INVOICE_SENT,
    EventStatus.WIN,
  );
};

export const sortLostEvents = (events: any[]) => {
  return sortEventsByStatus(events, EventStatus.LOST);
};

export const sortPastEvents = (events: any[]) => {
  return sortEventsByStatus(
    events,
    EventStatus.INVOICE_OVERDUE,
    EventStatus.DONE,
    EventStatus.INVOICE_SENT,
    EventStatus.WIN,
  );
};

export const sortOverdueEvents = (events: any[]) => {
  return sortEventsByStatus(events, EventStatus.INVOICE_OVERDUE);
};
