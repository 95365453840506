import { CardContainer, Typography, BookedDateAlert, Alert } from 'components';
import { NewEnquiryAlert } from 'components/BookedData/NewEnquiryAlert';
import { NewEnquiryPopUp } from 'components/BookedData/NewEnquiryPopup';
import { UTMSource } from 'enum/utm-source.enum';
import { MainEvent } from 'interface/event/main-event.interface';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EventStatus } from 'enum/event-status.enum';
import { getTimeStr } from 'utils/utils';

export const AssistantSection = (event: MainEvent) => {
  const intl = useIntl();
  const tripData = event.tripData;
  const distance = tripData?.distance?.text;
  const duration = tripData?.duration?.value;
  const [isNewEnquiryPopUpOpended, setNewEnquiryPopUpOpen] =
    useState<boolean>(true);

  return (
    <>
      <CardContainer className="p-4 w-full flex flex-col space-y-4">
        <Typography variant="h1" className="text-gray-900 font-semibold">
          {intl.formatMessage(
            {
              id: 'event-details.assistant.header',
            },
            {
              b: (chunk: any) => (
                <Typography variant="body" className="text-klaq-500 text-sm">
                  {chunk.join()}
                </Typography>
              ),
            },
          )}
        </Typography>
        {event.status === EventStatus.INBOX &&
          (event.source !== UTMSource.DIRECT ? (
            <NewEnquiryPopUp
              event={event}
              isOpen={isNewEnquiryPopUpOpended}
              setOpen={setNewEnquiryPopUpOpen}
            />
          ) : (
            <NewEnquiryAlert event={event} />
          ))}

        <BookedDateAlert event={event} />
        {!!distance && !!duration && (
          <Alert
            title={intl.formatMessage({
              id: 'event-details.assistant.location.infobox.title',
            })}
            status="info"
          >
            <div className="flex flex-col">
              <Typography variant="body" className="text-blue-700">
                {intl.formatMessage(
                  {
                    id: 'event-details.assistant.location.infobox.content',
                  },
                  {
                    distance: distance,
                    duration: getTimeStr(duration),
                    b: (chunk: any) => (
                      <Typography
                        variant="subtitle"
                        className="text-blue-700 font-semibold"
                      >
                        {chunk.join()}
                      </Typography>
                    ),
                  },
                )}
              </Typography>
            </div>
          </Alert>
        )}
      </CardContainer>
    </>
  );
};
