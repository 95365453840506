import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button, SelectField } from 'components';
import { Timezone } from 'enum/timezone.enum';
import { useFormik } from 'formik';
import { PageLayout } from 'layouts';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';
import { useUpdateSettings } from 'redux/Settings/hooks';
import { PATHS } from 'routes';
import { PreferencesSkeleton2 } from './Skeleton';
import {
  settingsInitialValues,
  validationSettingsSchema,
} from './settings-form';
export const Preferences = () => {
  type TimezoneType = keyof typeof Timezone;

  const [{ isLoading: isFetchingUser }, fetchUser] = useFetchUser();
  const [{ isLoading: isUpdatingSettings }, updateSettings] =
    useUpdateSettings();

  const intl = useIntl();
  const user = useSelector(getUser);
  const navigate = useNavigate();

  const settingsFormik = useFormik({
    initialValues: user
      ? {
          ...settingsInitialValues,
          ...user.settings,
          pricePerKm: parseFloat(
            user && user.settings && user.settings.pricePerKm
              ? String(user.settings.pricePerKm)
              : '0',
          ),
        }
      : { ...settingsInitialValues },
    validationSchema: validationSettingsSchema,
    onSubmit: async (values) => {
      updateSettings(values);
    },
    enableReinitialize: true,
  });

  const handlePrevious = () => {
    navigate(PATHS.SETTINGS);
  };

  const handleResetSettingsPreference = () => {
    settingsFormik.setValues({
      ...settingsFormik.values,
      ...user.settings,
      pricePerKm: parseFloat(
        user && user.settings && user.settings.pricePerKm
          ? String(user.settings.pricePerKm)
          : '0',
      ),
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <PageLayout>
      <div className="space-y-8 divide-y divide-gray-900/10">
        <div>
          <Button
            type="button"
            variant="text"
            color="secondary"
            leadingIcon={
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            }
            onClick={handlePrevious}
          >
            {intl.formatMessage({
              id: 'customers.customer-details.button.previous',
            })}
          </Button>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {intl.formatMessage({
                id: 'settings.timezone.header',
              })}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.timezone.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <PreferencesSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-2 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <SelectField
                      label={intl.formatMessage({
                        id: 'settings.timezone.select',
                      })}
                      name="timeZone"
                      onChange={settingsFormik.handleChange}
                      value={settingsFormik.values.timeZone}
                      disabled
                    >
                      {(Object.keys(Timezone) as Array<TimezoneType>).map(
                        (key) => (
                          <option key={key} value={Timezone[key]}>
                            {Timezone[key]}
                          </option>
                        ),
                      )}
                    </SelectField>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                <Button
                  type="button"
                  variant="text"
                  color="secondary"
                  onClick={handleResetSettingsPreference}
                >
                  {intl.formatMessage({
                    id: 'settings.button.cancel',
                  })}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="lg"
                  isLoading={isUpdatingSettings}
                >
                  {intl.formatMessage({
                    id: 'settings.button.submit',
                  })}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
