import { Button, SubscriptionCard } from 'components';
import { Typography } from 'components/Typography';

import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { PageLayout } from 'layouts';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

export const Subscriptions = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(PATHS.SETTINGS);
  };
  return (
    <PageLayout>
      <div className="space-y-8 divide-y divide-gray-900/10">
        <div>
          <Button
            type="button"
            variant="text"
            color="secondary"
            leadingIcon={
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            }
            onClick={handlePrevious}
          >
            {intl.formatMessage({
              id: 'customers.customer-details.button.previous',
            })}
          </Button>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.subscriptions.overview.header',
              })}
            </Typography>
            <Typography
              variant="body"
              className="mt-1 text-sm leading-6 text-gray-600"
            >
              {intl.formatMessage({
                id: 'settings.subscriptions.overview.description',
              })}
            </Typography>
          </div>
          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                  <SubscriptionCard />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.subscriptions.details.header',
              })}
            </Typography>
            <Typography
              variant="body"
              className="mt-1 text-sm leading-6 text-gray-600"
            >
              {intl.formatMessage({
                id: 'settings.subscriptions.details.description',
              })}
            </Typography>
          </div>
          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Typography
                    variant="body"
                    className="mt-1 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage({
                      id: 'settings.subscriptions.current-plan.beta.description',
                    })}
                  </Typography>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};
