import * as Yup from 'yup';
import { CustomerType } from 'enum/customer-type.enum';

export const initialValues = {
  customer: {
    name: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    type: CustomerType.PRIVATE,
    address: '',
    zipcode: '',
    city: '',
    country: '',
    legalVATNumber: '',
    legalRegistrationNumber: '',
  },
  title: '',
  subEvents: [
    {
      id: '',
      date: '',
      startTime: '',
      endTime: '',
      arrivalTime: '',
      address: '',
      city: '',
      zipcode: '',
      country: '',
      type: '',
      guests: 0,
      publicEvent: 'true',
    },
  ],
  note: '',
  budget: 0,
  contactFirstName: '',
  contactLastName: '',
  contactPhoneNumber: '',
};

export const validationSchema = Yup.object().shape({
  customer: Yup.object()
    .shape({
      email: Yup.string().email('Invalid email'),
      phone: Yup.string().matches(
        /^[0-9+\s]+$/,
        'Phone number must contain only numbers',
      ),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
    })
    .test({
      name: 'either-email-or-phone',
      exclusive: true,
      test: (values) => {
        const { email, phone } = values;
        return !!email || !!phone;
      },
      message: 'Either email or phone must be specified',
    }),
  title: Yup.string(),
  subEvents: Yup.array().of(
    Yup.object().shape({
      date: Yup.string().required('Required'),
      address: Yup.string().required('Required'),
      zipcode: Yup.string().required('Required'),
      type: Yup.string(),
      guests: Yup.number(),
    }),
  ),
});
