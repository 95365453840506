import {
  BuildingLibraryIcon,
  ClockIcon,
  InformationCircleIcon,
  MapIcon,
  MapPinIcon,
  PencilSquareIcon,
  ShareIcon,
  TrashIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import {
  CardContainer,
  DangerModal,
  EditEventModal,
  EventBadgeButton,
  EventMapV2,
  KebabMenu,
  MailPopUp,
} from 'components';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { CustomerType } from 'enum/customer-type.enum';
import EditCustomer from 'pages/Customers/EditCustomer';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpdateArchivedStatus } from 'redux/MainEvent/hooks';
import { useDownloadRoadmap } from 'redux/Roadmap/hooks';
import { PATHS } from 'routes';
import { formatAddress } from 'utils/address';
import { formatPhoneNumber } from 'utils/customer';
import { getRemainingTime } from 'utils/time';
import { getDayStr, getMonthStr } from 'utils/utils';
import { MainEventDetailsPageProps } from './EventDetailsPage';

import { useSelector } from 'react-redux';
import { useFetchUser } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';

export const EventDetailsHeader = (props: MainEventDetailsPageProps) => {
  const { event } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const [, fetchUser] = useFetchUser();
  const user = useSelector(getUser);

  const [shouldOpenEditor, setOpenEditor] = useState(false);
  const [shouldOpenDelete, setOpenDelete] = useState(false);
  const [shouldOpenEditCustomer, setOpenEditCustomer] = useState(false);
  const [, updateArchivedStatus] = useUpdateArchivedStatus();
  const [, downloadRoadmap] = useDownloadRoadmap();
  const [isMailPopUpOpened, setMailPopupOpen] = useState<boolean>(false);

  const sharePublicRoadmap = () => {
    setMailPopupOpen(true);
  };
  const eventDate = new Date(event.subEvents[0].date);

  const handleDeleteEvent = () => {
    setOpenDelete(false);
    updateArchivedStatus(event.id, true);
    navigate(PATHS.EVENTS);
  };

  const menu = [
    {
      name: intl.formatMessage({ id: 'event-details.button.edit' }),
      icon: PencilSquareIcon,
      onClick: () => setOpenEditor(true),
    },
    {
      name: intl.formatMessage({ id: 'event-details.button.download' }),
      icon: MapIcon,
      onClick: () => downloadRoadmap(event.id, user.id),
    },
    {
      name: intl.formatMessage({ id: 'event-details.button.share' }),
      icon: ShareIcon,
      onClick: () => sharePublicRoadmap(),
    },
    {
      name: intl.formatMessage({ id: 'event-details.button.delete' }),
      icon: TrashIcon,
      onClick: () => setOpenDelete(true),
    },
  ];

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <div className="flex flex-col space-y-4">
        <CardContainer className="p-4 w-full flex flex-row items-center justify-between">
          <div className="flex flex-row space-x-4 items-center">
            <div className="border-gray-200 border-r pr-8 text-klaq-600 flex flex-row space-x-2 items-center">
              <Typography variant="body" className="text-md text-klaq-600">
                {intl.formatMessage({
                  id: getDayStr(eventDate),
                })}
              </Typography>

              <Typography
                variant="body"
                className="text-xl font-bold text-klaq-600"
              >
                {format(eventDate, 'dd')}
              </Typography>
              <Typography variant="body" className="text-md text-klaq-600">
                {intl.formatMessage({
                  id: getMonthStr(new Date(eventDate)),
                })}
              </Typography>
              <Typography variant="body" className="text-md text-klaq-600">
                {format(eventDate, 'yyyy')}
              </Typography>
            </div>
            <EventBadgeButton status={event.status} eventId={event.id} />
          </div>
          <Typography
            variant="subtitle"
            className="flex flex-row space-x-4 items-center"
          >
            <Typography
              variant="subtitle"
              className="text-gray-900 font-semibold text-base"
            >
              {getRemainingTime(eventDate)}
            </Typography>
            <KebabMenu items={menu} buttonSize="lg" />
          </Typography>
        </CardContainer>
        <div className="flex flex-row space-x-4">
          <CardContainer className="px-4 py-5 sm:p-6 w-1/2 flex flex-col space-y-4">
            <Typography
              variant="subtitle"
              className="flex flex-row items-center justify-between"
            >
              <Typography
                variant="subtitle"
                className="text-gray-900 font-semibold text-base"
              >
                {event.title}
              </Typography>
              <button onClick={() => setOpenEditor(true)}>
                <PencilSquareIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
              </button>
            </Typography>
            <div className="border-t border-gray-900/4"></div>
            <div className="flex flex-col space-y-4">
              <Typography
                variant="subtitle"
                className="flex flex-row space-x-4 items-center"
              >
                <ClockIcon className="h-5 w-5  text-gray-400" />
                <Typography
                  variant="subtitle"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {event.subEvents[0].startTime
                    ? event.subEvents[0].startTime
                    : 'Aucune heure de début renseignée'}{' '}
                  -{' '}
                  {event.subEvents[0].endTime
                    ? event.subEvents[0].endTime
                    : 'Aucune heure de fin renseignée'}
                </Typography>
              </Typography>

              <Typography
                variant="subtitle"
                className="flex flex-row space-x-4 items-center"
              >
                {event.customer?.type === CustomerType.PRIVATE ? (
                  <UserCircleIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <BuildingLibraryIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
                <Typography
                  variant="subtitle"
                  className="text-sm font-semibold leading-6 text-gray-900 flex flex-row space-x-2 items-center hover:text-gray-600"
                >
                  <button onClick={() => setOpenEditCustomer(true)}>
                    {event.customer?.type === CustomerType.PRIVATE ? (
                      <Typography variant="subtitle">
                        {event.customer.name}{' '}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle">
                        {`${event.customer.firstName} ${event.customer.lastName} (${event.customer.name})`}{' '}
                      </Typography>
                    )}
                  </button>

                  {event.customer?.phone ? (
                    <Typography
                      variant="body"
                      className="text-sm font-semibold  leading-6 text-gray-400"
                    >
                      <a href={`tel:${event.customer.phone}`}>
                        {formatPhoneNumber(event.customer.phone)}
                      </a>
                    </Typography>
                  ) : (
                    <Typography
                      variant="body"
                      className="text-sm font-semibold  leading-6 text-gray-400"
                    >
                      <a
                        href={`mailto:${event.customer?.email}`}
                        className="text-sm text-gray-900"
                      >
                        {event.customer?.email}
                      </a>
                    </Typography>
                  )}
                  <span
                    className="hover:text-gray-600 hover:cursor-pointer"
                    onClick={() =>
                      navigate(`${PATHS.CUSTOMERS}/${event.customer.id}`)
                    }
                  >
                    <InformationCircleIcon className="h-5 w-5 text-gray-400" />
                  </span>
                </Typography>
              </Typography>

              <Typography
                variant="subtitle"
                className="flex flex-row space-x-4 items-center"
              >
                <MapPinIcon className="h-5 w-5  text-gray-400" />
                <Typography
                  variant="subtitle"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {formatAddress({
                    address: event.subEvents[0].address,
                    zip: event.subEvents[0].zipcode,
                    city: event.subEvents[0].city,
                    country: event.subEvents[0].country,
                  })}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle"
                className="flex flex-row space-x-4 items-center"
              >
                <UserGroupIcon className="h-5 w-5  text-gray-400" />
                <Typography
                  variant="subtitle"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {event.subEvents[0].guests} personnes attendues (évènement
                  public: {event.subEvents[0].publicEvent ? 'oui' : 'non'})
                </Typography>
              </Typography>
            </div>
          </CardContainer>
          <CardContainer className="px-4 py-5 sm:p-4 min-h-full w-1/2 flex flex-col space-y-4 flex-grow">
            <EventMapV2 eventId={event.id} event={event} />
          </CardContainer>
        </div>
      </div>
      <EditEventModal
        isOpen={shouldOpenEditor}
        setOpen={setOpenEditor}
        event={event}
      />
      <DangerModal
        isOpen={shouldOpenDelete}
        setOpen={setOpenDelete}
        onClick={() => handleDeleteEvent()}
        title={intl.formatMessage({ id: 'event-details.delete-modal.title' })}
        message={intl.formatMessage({
          id: 'event-details.delete-modal.message',
        })}
        button1={intl.formatMessage({
          id: 'event-details.delete-modal.button.delete',
        })}
        button2={intl.formatMessage({
          id: 'event-details.delete-modal.button.cancel',
        })}
      />
      <EditCustomer
        isOpen={shouldOpenEditCustomer}
        setOpen={setOpenEditCustomer}
        customer={event.customer}
      />

      <MailPopUp
        isOpen={isMailPopUpOpened}
        setOpen={setMailPopupOpen}
        type="ROADMAP"
        content={{
          to: event.customer.email,
          message: intl.formatMessage(
            {
              id: 'email.template.roadmap.content',
            },
            {
              type: event.title.toLowerCase(),
              date: format(new Date(eventDate), 'dd/MM/yyyy'),
              stageName: user.stageName,
            },
          ),
          subject: intl.formatMessage(
            {
              id: 'email.template.roadmap.subject',
            },
            { stageName: user.stageName },
          ),
        }}
        documentId={event.id}
      />
    </>
  );
};
