import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../states';
import { User } from 'interface/user/user.interface';

export type UserSliceType = User;

export const userSlice: any = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserSliceType>) => {
      state.user = action.payload;
    },
    resetUser: () => initialState,
  },
});

export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
