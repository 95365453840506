import React from 'react';
import { classNames } from 'utils/utils';

export type TypographyProps = {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body' | 'subtitle';
  children: React.ReactNode;
  className?: string;
};

export const Typography = (props: TypographyProps) => {
  const { variant, children, className } = props;
  const baseClasses = 'leading-6';

  switch (variant) {
    case 'h1':
      return (
        <h1
          className={classNames(
            baseClasses,
            `text-base font-semibold text-gray-900`,
            className,
          )}
        >
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          className={classNames(
            baseClasses,
            `text-lg font-semibold text-gray-900`,
            className,
          )}
        >
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          className={classNames(
            baseClasses,
            `text-md font-semibold text-gray-900`,
            className,
          )}
        >
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4
          className={classNames(
            baseClasses,
            `text-sm font-semibold text-gray-900`,
            className,
          )}
        >
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5
          className={classNames(
            baseClasses,
            `text-xs font-semibold text-gray-900`,
            className,
          )}
        >
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6
          className={classNames(
            baseClasses,
            `text-xs font-semibold text-gray-700`,
            className,
          )}
        >
          {children}
        </h6>
      );
    case 'body':
      return (
        <p
          className={classNames(
            baseClasses,
            `text-sm text-gray-500`,
            className,
          )}
        >
          {children}
        </p>
      );
    case 'subtitle':
      return (
        <span
          className={classNames(
            baseClasses,
            `text-sm font-semibold text-gray-700`,
            className,
          )}
        >
          {children}
        </span>
      );
    default:
      return (
        <Typography
          variant="subtitle"
          className={classNames(baseClasses, className)}
        >
          {children}
        </Typography>
      );
  }
};

export default Typography;
