import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components';
import { Typography } from 'components/Typography';

import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchUser, useRequestResetPassword } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';
import { PATHS } from 'routes';

export const Security = () => {
  const intl = useIntl();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [{ isLoading: isSendingRequest }, requestResetPassword] =
    useRequestResetPassword();
  const [, fetchUser] = useFetchUser();

  const user = useSelector(getUser);

  const handleRequestResetPassword = () => {
    setIsButtonDisabled(true);

    if (!user) return;
    requestResetPassword({ email: user.email });
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isButtonDisabled) {
      timeoutId = setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000); // 5 seconds
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isButtonDisabled]);

  useEffect(() => {
    fetchUser();
  }, []);

  const handlePrevious = () => {
    navigate(PATHS.SETTINGS);
  };

  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="space-y-8 divide-y divide-gray-900/10">
        <div>
          <Button
            type="button"
            variant="text"
            color="secondary"
            leadingIcon={
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            }
            onClick={handlePrevious}
          >
            {intl.formatMessage({
              id: 'customers.customer-details.button.previous',
            })}
          </Button>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.change-password.header',
              })}
            </Typography>
            <Typography
              variant="body"
              className="mt-1 text-sm leading-6 text-gray-600"
            >
              {intl.formatMessage({
                id: 'settings.change-password.description',
              })}
            </Typography>
          </div>

          <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                  <Typography
                    variant="h3"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {intl.formatMessage({
                      id: 'settings.change-password.send-mail.header',
                    })}
                  </Typography>
                  <Typography
                    variant="body"
                    className="mt-2 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage(
                      {
                        id: 'settings.change-password.send-mail.description',
                      },
                      {
                        b: (chunks: any) => <b>{chunks}</b>,
                        mail: user?.email,
                      },
                    )}
                  </Typography>
                </div>

                <div className="sm:col-span-full">
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="lg"
                    leadingIcon={
                      <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                    }
                    onClick={handleRequestResetPassword}
                    isLoading={isSendingRequest}
                    disabled={isButtonDisabled}
                  >
                    {intl.formatMessage({
                      id: 'settings.change-password.send-mail.button',
                    })}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};
