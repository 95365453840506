import { DiscountType } from 'enum/discount-type.enum';

export const initialValues = {
  quotes: [
    {
      id: '',
      issuedOn: '',
      validUntil: '',
      createdAt: '',
      updatedAt: '',
      status: '',
      number: '',
      isOpen: false,
      products: [
        {
          quantity: 1,
          title: '',
          description: '',
          vtaRate: '20',
          price: 0,
          priceWithTaxes: 0,
          discount: 0,
          discountType: DiscountType.PERCENT,
        },
      ],
      orderFormId: '',
      customer: {
        address: '',
        city: '',
        country: '',
        zipcode: '',
        name: '',
      },
      object: '',
      footNote: '',
      isChecked: false,
    },
  ],
};
