import { LinkIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Spinner } from 'components/Spinner';

type IntegrationButtonProps = {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  header: string;
  description: string;
  isIntegrated: boolean;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const IntegrationButton = (props: IntegrationButtonProps) => {
  const {
    Icon,
    header,
    description,
    isIntegrated,
    onClick,
    isLoading,
    isDisabled,
  } = props;
  return !isIntegrated ? (
    <button
      disabled={isDisabled}
      type="button"
      onClick={onClick}
      className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
    >
      <span className="flex min-w-0 flex-1 items-center space-x-3">
        <span className="block flex-shrink-0">
          <Icon className="h-10 w-10 rounded-full" />
        </span>
        <span className="block min-w-0 flex-1">
          <span className="block truncate text-sm font-medium text-gray-900">
            {header}
          </span>
          <span className="block truncate text-sm font-medium text-gray-500">
            {description}
          </span>
        </span>
      </span>
      <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
        {isLoading ? (
          <Spinner size={'small'} color={'primary'} />
        ) : (
          <PlusIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        )}
      </span>
    </button>
  ) : (
    <div className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
      <span className="flex min-w-0 flex-1 items-center space-x-3">
        <span className="block flex-shrink-0">
          <Icon className="h-10 w-10 rounded-full" />
        </span>
        <span className="block min-w-0 flex-1">
          <span className="block truncate text-sm font-medium text-gray-900">
            {header}
          </span>
          <span className="block truncate text-sm font-medium text-gray-500">
            {description}
          </span>
        </span>
      </span>
      <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
        <LinkIcon className="h-5 w-5 text-success-600" />
      </span>
    </div>
  );
};
