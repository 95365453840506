import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Checkbox,
  TextArea,
  TextFieldWithCurrency,
  Typography,
  UploadDocumentZone,
  UploadedDocument,
} from 'components';
import { useFormik } from 'formik';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';
import {
  useDeleteBillingCover,
  useUpdateBillingCover,
  useUpdateSettings,
} from 'redux/Settings/hooks';
import { PATHS } from 'routes';
import { BillingsSkeleton2 } from './Skeleton/BillingsSkeleton';
import {
  billingSettingsInitialValues,
  validationBillingSettingsSchema,
} from './billings-form';
import { BillingCoverType } from 'enum/cover-type.enum';

export const BillingSettings = () => {
  const intl = useIntl();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const [quoteFrontCoverFiles, setQuoteFrontCoverFiles] = useState<File[]>([]);
  const [quoteBackCoverFiles, setQuoteBackCoverFiles] = useState<File[]>([]);
  const [invoiceFrontCoverFiles, setInvoiceFrontCoverFiles] = useState<File[]>(
    [],
  );
  const [invoiceBackCoverFiles, setInvoiceBackCoverFiles] = useState<File[]>(
    [],
  );

  const [{ isLoading: isFetchingUser }, fetchUser] = useFetchUser();
  const [{ isLoading: isUpdatingSettings }, updateSettings] =
    useUpdateSettings();
  const [{ isLoading: isUpdatingCover }, updateCover] = useUpdateBillingCover();
  const [{ isLoading: isDeletingCover }, deleteCover] = useDeleteBillingCover();

  const settingsFormik = useFormik({
    initialValues: user
      ? {
          ...billingSettingsInitialValues,
          ...user.settings,
          pricePerKm: parseFloat(
            user && user.settings && user.settings.pricePerKm
              ? String(user.settings.pricePerKm)
              : '0',
          ),
        }
      : { ...billingSettingsInitialValues },
    validationSchema: validationBillingSettingsSchema,
    onSubmit: async (values) => {
      updateSettings(values);
    },
    enableReinitialize: true,
  });

  const handlePrevious = () => {
    navigate(PATHS.SETTINGS);
  };

  const handleResetSettingsPreference = () => {
    settingsFormik.setValues({
      ...settingsFormik.values,
      ...user.settings,
      pricePerKm: parseFloat(
        user && user.settings && user.settings.pricePerKm
          ? String(user.settings.pricePerKm)
          : '0',
      ),
    });
  };

  const handleQuoteDeleteCover = async (type: BillingCoverType) => {
    if (type === BillingCoverType.QUOTE_FRONT) {
      setQuoteFrontCoverFiles([]);
    } else {
      setQuoteBackCoverFiles([]);
    }
    await deleteCover(type);
    await fetchUser();
  };

  const handleInvoiceDeleteCover = async (type: BillingCoverType) => {
    if (type === BillingCoverType.INVOICE_FRONT) {
      setInvoiceFrontCoverFiles([]);
    } else {
      setInvoiceBackCoverFiles([]);
    }
    await deleteCover(type);
    await fetchUser();
  };

  const handleInvoiceUpdateCover = async (type: BillingCoverType) => {
    await updateCover(
      type,
      type === BillingCoverType.INVOICE_FRONT
        ? invoiceFrontCoverFiles[0]
        : invoiceBackCoverFiles[0],
    );

    if (type === BillingCoverType.INVOICE_FRONT) {
      setInvoiceFrontCoverFiles([]);
    } else {
      setInvoiceBackCoverFiles([]);
    }
    await fetchUser();
  };

  const handleQuoteUpdateCover = async (type: BillingCoverType) => {
    await updateCover(
      type,
      type === BillingCoverType.QUOTE_FRONT
        ? quoteFrontCoverFiles[0]
        : quoteBackCoverFiles[0],
    );

    if (type === BillingCoverType.QUOTE_FRONT) {
      setQuoteFrontCoverFiles([]);
    } else {
      setQuoteBackCoverFiles([]);
    }
    await fetchUser();
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <PageLayout>
      <div className="space-y-8 divide-y divide-gray-900/10 mb-10">
        <div>
          <Button
            type="button"
            variant="text"
            color="secondary"
            leadingIcon={
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            }
            onClick={handlePrevious}
          >
            {intl.formatMessage({
              id: 'customers.customer-details.button.previous',
            })}
          </Button>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.footnote.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.footnote.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-full">
                    <div className="relative flex flex-col items-start">
                      <div className="ml-3 text-sm leading-6">
                        <Typography
                          variant="h2"
                          className="text-base font-semibold leading-7 text-gray-900"
                        >
                          {intl.formatMessage({
                            id: 'settings.footnote.options.auto-footnote.header',
                          })}
                        </Typography>
                        <p className="text-gray-500">
                          {intl.formatMessage({
                            id: 'settings.footnote.options.auto-footnote.description',
                          })}
                        </p>
                      </div>
                      <div className="ml-3 mt-3 flex h-6 items-center">
                        <input
                          onChange={settingsFormik.handleChange}
                          name="isCustomFootNoteEnabled"
                          type="checkbox"
                          checked={
                            settingsFormik.values.isCustomFootNoteEnabled
                          }
                          className="h-4 w-4 rounded border-gray-300 text-klaq-600 focus:ring-klaq-600"
                        />
                        <label className="ml-2 text-sm leading-6 text-gray-600">
                          {intl.formatMessage({
                            id: 'settings.footnote.options.auto-footnote.checkbox',
                          })}
                        </label>
                      </div>
                    </div>
                  </div>
                  <TextArea
                    label={intl.formatMessage({
                      id: 'settings.footnote.options.custom-footnote.header',
                    })}
                    name="customFootNote"
                    value={settingsFormik.values.customFootNote}
                    onChange={settingsFormik.handleChange}
                    placeholder={intl.formatMessage({
                      id: 'settings.footnote.options.custom-footnote.input',
                    })}
                    className="sm:col-span-full ml-3"
                    error={
                      settingsFormik.errors.customFootNote &&
                      settingsFormik.touched.customFootNote
                        ? intl.formatMessage({
                            id: 'settings.footnote.options.custom-footnote.error',
                          })
                        : null
                    }
                  />
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                <Button
                  type="button"
                  variant="text"
                  color="secondary"
                  onClick={handleResetSettingsPreference}
                >
                  {intl.formatMessage({
                    id: 'settings.button.cancel',
                  })}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="lg"
                  isLoading={isUpdatingSettings}
                >
                  {intl.formatMessage({
                    id: 'settings.button.submit',
                  })}
                </Button>
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.vhr.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.vhr.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-full">
                    <div className="relative flex flex-col items-start">
                      <div className="ml-3 text-sm leading-6">
                        <Typography
                          variant="h2"
                          className="text-base font-semibold leading-7 text-gray-900"
                        >
                          {intl.formatMessage({
                            id: 'settings.vhr.options.auto-quote.header',
                          })}
                        </Typography>
                        <p className="text-gray-500">
                          {intl.formatMessage({
                            id: 'settings.vhr.options.auto-quote.description',
                          })}
                        </p>
                      </div>
                      <div className="ml-3 mt-3 flex h-6 items-center">
                        <input
                          onChange={settingsFormik.handleChange}
                          name="isVHREnabled"
                          type="checkbox"
                          checked={settingsFormik.values.isVHREnabled}
                          className="h-4 w-4 rounded border-gray-300 text-klaq-600 focus:ring-klaq-600"
                        />
                        <label className="ml-2 text-sm leading-6 text-gray-600">
                          {intl.formatMessage({
                            id: 'settings.vhr.options.auto-quote.checkbox',
                          })}
                        </label>
                      </div>
                    </div>
                  </div>
                  <TextFieldWithCurrency
                    label={intl.formatMessage({
                      id: 'settings.vhr.options.travel-fees.header',
                    })}
                    name="pricePerKm"
                    value={settingsFormik.values.pricePerKm}
                    onChange={settingsFormik.handleChange}
                    type="number"
                    placeholder={intl.formatMessage({
                      id: 'settings.vhr.options.travel-fees.input',
                    })}
                    className="sm:col-span-2 ml-3"
                    error={
                      settingsFormik.errors.pricePerKm &&
                      settingsFormik.touched.pricePerKm
                        ? intl.formatMessage({
                            id: 'settings.vhr.options.travel-fees.error',
                          })
                        : null
                    }
                  />
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                <Button
                  type="button"
                  variant="text"
                  color="secondary"
                  onClick={handleResetSettingsPreference}
                >
                  {intl.formatMessage({
                    id: 'settings.button.cancel',
                  })}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="lg"
                  isLoading={isUpdatingSettings}
                >
                  {intl.formatMessage({
                    id: 'settings.button.submit',
                  })}
                </Button>
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.cover.activation.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.cover.activation.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <Checkbox
                    onChange={settingsFormik.handleChange}
                    name={'isQuoteFrontCoverEnabled'}
                    checked={settingsFormik.values.isQuoteFrontCoverEnabled}
                    className="sm:col-span-3"
                    label={intl.formatMessage({
                      id: 'settings.cover.activation.options.quote.front.label',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.cover.activation.options.quote.front.description',
                    })}
                  />
                  <Checkbox
                    onChange={settingsFormik.handleChange}
                    name={'isQuoteBackCoverEnabled'}
                    checked={settingsFormik.values.isQuoteBackCoverEnabled}
                    className="sm:col-span-3"
                    label={intl.formatMessage({
                      id: 'settings.cover.activation.options.quote.back.label',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.cover.activation.options.quote.back.description',
                    })}
                  />
                  <Checkbox
                    onChange={settingsFormik.handleChange}
                    name={'isInvoiceFrontCoverEnabled'}
                    checked={settingsFormik.values.isInvoiceFrontCoverEnabled}
                    className="sm:col-span-3"
                    label={intl.formatMessage({
                      id: 'settings.cover.activation.options.invoice.front.label',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.cover.activation.options.invoice.front.description',
                    })}
                  />
                  <Checkbox
                    onChange={settingsFormik.handleChange}
                    name={'isInvoiceBackCoverEnabled'}
                    checked={settingsFormik.values.isInvoiceBackCoverEnabled}
                    className="sm:col-span-3"
                    label={intl.formatMessage({
                      id: 'settings.cover.activation.options.invoice.back.label',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.cover.activation.options.invoice.back.description',
                    })}
                  />
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                <Button
                  type="button"
                  variant="text"
                  color="secondary"
                  onClick={handleResetSettingsPreference}
                >
                  {intl.formatMessage({
                    id: 'settings.button.cancel',
                  })}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="lg"
                  isLoading={isUpdatingSettings}
                >
                  {intl.formatMessage({
                    id: 'settings.button.submit',
                  })}
                </Button>
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.cover.quote.front.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.cover.quote.front.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {user &&
                  user.settings &&
                  !user.settings.quoteFrontCoverDocumentId ? (
                    <UploadDocumentZone
                      fileList={quoteFrontCoverFiles}
                      setFiles={setQuoteFrontCoverFiles}
                      maximumFiles={1}
                      className="sm:col-span-full"
                      mimeTypeAllowed={['.pdf']}
                    />
                  ) : (
                    user &&
                    user.settings &&
                    user.settings.quoteFrontCoverDocumentId && (
                      <UploadedDocument
                        className="sm:col-span-full"
                        documentId={user.settings.quoteFrontCoverDocumentId}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                {user &&
                  user.settings &&
                  user.settings.quoteFrontCoverDocumentId && (
                    <Button
                      type="button"
                      variant="text"
                      color="secondary"
                      onClick={() =>
                        handleQuoteDeleteCover(BillingCoverType.QUOTE_FRONT)
                      }
                      isLoading={isDeletingCover}
                    >
                      {intl.formatMessage({
                        id: 'settings.button.delete',
                      })}
                    </Button>
                  )}
                {user &&
                user.settings &&
                user.settings.quoteFrontCoverDocumentId ? null : (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="lg"
                    isLoading={isUpdatingCover}
                    onClick={() =>
                      handleQuoteUpdateCover(BillingCoverType.QUOTE_FRONT)
                    }
                  >
                    {intl.formatMessage({
                      id: 'settings.button.submit',
                    })}
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.cover.quote.back.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.cover.quote.back.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {user &&
                  user.settings &&
                  !user.settings.quoteBackCoverDocumentId ? (
                    <UploadDocumentZone
                      fileList={quoteBackCoverFiles}
                      setFiles={setQuoteBackCoverFiles}
                      maximumFiles={1}
                      className="sm:col-span-full"
                      mimeTypeAllowed={['.pdf']}
                    />
                  ) : (
                    user &&
                    user.settings &&
                    user.settings.quoteBackCoverDocumentId && (
                      <UploadedDocument
                        className="sm:col-span-full"
                        documentId={user.settings.quoteBackCoverDocumentId}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                {user &&
                  user.settings &&
                  user.settings.quoteBackCoverDocumentId && (
                    <Button
                      type="button"
                      variant="text"
                      color="secondary"
                      onClick={() =>
                        handleQuoteDeleteCover(BillingCoverType.QUOTE_BACK)
                      }
                      isLoading={isDeletingCover}
                    >
                      {intl.formatMessage({
                        id: 'settings.button.delete',
                      })}
                    </Button>
                  )}
                {user &&
                user.settings &&
                user.settings.quoteBackCoverDocumentId ? null : (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="lg"
                    isLoading={isUpdatingCover}
                    onClick={() =>
                      handleQuoteUpdateCover(BillingCoverType.QUOTE_BACK)
                    }
                  >
                    {intl.formatMessage({
                      id: 'settings.button.submit',
                    })}
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.cover.invoice.front.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.cover.invoice.front.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {user &&
                  user.settings &&
                  !user.settings.invoiceFrontCoverDocumentId ? (
                    <UploadDocumentZone
                      fileList={invoiceFrontCoverFiles}
                      setFiles={setInvoiceFrontCoverFiles}
                      maximumFiles={1}
                      className="sm:col-span-full"
                      mimeTypeAllowed={['.pdf']}
                    />
                  ) : (
                    user &&
                    user.settings &&
                    user.settings.invoiceFrontCoverDocumentId && (
                      <UploadedDocument
                        className="sm:col-span-full"
                        documentId={user.settings.invoiceFrontCoverDocumentId}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                {user &&
                  user.settings &&
                  user.settings.quoteFrontCoverDocumentId && (
                    <Button
                      type="button"
                      variant="text"
                      color="secondary"
                      onClick={() =>
                        handleInvoiceDeleteCover(BillingCoverType.INVOICE_FRONT)
                      }
                      isLoading={isDeletingCover}
                    >
                      {intl.formatMessage({
                        id: 'settings.button.delete',
                      })}
                    </Button>
                  )}
                {user &&
                user.settings &&
                user.settings.invoiceFrontCoverDocumentId ? null : (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="lg"
                    isLoading={isUpdatingCover}
                    onClick={() =>
                      handleInvoiceUpdateCover(BillingCoverType.INVOICE_FRONT)
                    }
                  >
                    {intl.formatMessage({
                      id: 'settings.button.submit',
                    })}
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.cover.invoice.back.header',
              })}
            </Typography>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.cover.invoice.back.description',
              })}
            </p>
          </div>
          {isFetchingUser ? (
            <BillingsSkeleton2 />
          ) : (
            <form
              onSubmit={settingsFormik.handleSubmit}
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {user &&
                  user.settings &&
                  !user.settings.invoiceBackCoverDocumentId ? (
                    <UploadDocumentZone
                      fileList={invoiceBackCoverFiles}
                      setFiles={setInvoiceBackCoverFiles}
                      maximumFiles={1}
                      className="sm:col-span-full"
                      mimeTypeAllowed={['.pdf']}
                    />
                  ) : (
                    user &&
                    user.settings &&
                    user.settings.invoiceBackCoverDocumentId && (
                      <UploadedDocument
                        className="sm:col-span-full"
                        documentId={user.settings.invoiceBackCoverDocumentId}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-end gap-x-6 border-t border-gray-900/10 rounded-b-xl px-4 py-4 sm:px-8">
                {user &&
                  user.settings &&
                  user.settings.invoiceBackCoverDocumentId && (
                    <Button
                      type="button"
                      variant="text"
                      color="secondary"
                      onClick={() =>
                        handleInvoiceDeleteCover(BillingCoverType.INVOICE_BACK)
                      }
                      isLoading={isDeletingCover}
                    >
                      {intl.formatMessage({
                        id: 'settings.button.delete',
                      })}
                    </Button>
                  )}
                {user &&
                user.settings &&
                user.settings.invoiceBackCoverDocumentId ? null : (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="lg"
                    isLoading={isUpdatingCover}
                    onClick={() =>
                      handleInvoiceUpdateCover(BillingCoverType.INVOICE_BACK)
                    }
                  >
                    {intl.formatMessage({
                      id: 'settings.button.submit',
                    })}
                  </Button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
