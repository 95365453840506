import {
  ArrowLeftIcon,
  DocumentDuplicateIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as DropboxIcon } from 'assets/dropbox-icon.svg';
import { ReactComponent as GoogleCalendarIcon } from 'assets/icon-google-calendar.svg';
import { ReactComponent as GoogleDriveIcon } from 'assets/icon-google-drive.svg';
import { ReactComponent as GoogleIcon } from 'assets/icon-google.svg';
import { ReactComponent as OutlookIcon } from 'assets/icon-outlook.svg';
import {
  Button,
  IntegrationButton,
  Skeleton,
  ToastNotification,
  Tooltip,
} from 'components';
import { BetaBadge } from 'components/Badge/BetaBadge';
import { Typography } from 'components/Typography';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { PageLayout } from 'layouts';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGoogleCalendarInitiation,
  useGoogleDriveInitiation,
  useGoogleGenerateOAuthUrl,
} from 'redux/Google/hooks';
import { useFetchUser } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';
import { useUpdateUser } from 'redux/User/hooks';
import { PATHS } from 'routes';
import { initialValues, validationSchema } from '../Profile/form';

export const Integrations = () => {
  const intl = useIntl();

  const [{ isLoading: isFetchingUser }, fetchUser] = useFetchUser();
  const [, updateUser] = useUpdateUser();
  const user = useSelector(getUser);

  const [, getGoogleOAuthUrl] = useGoogleGenerateOAuthUrl();
  const [{ isLoading: isInitiatingGoogleDrive }, initiateGoogleDrive] =
    useGoogleDriveInitiation();
  const [{ isLoading: isInitiatingGoogleCalendar }, initiateGoogleCalendar] =
    useGoogleCalendarInitiation();

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...user,
      birthDate: user.birthDate
        ? format(new Date(user.birthDate), 'yyyy-MM-dd')
        : '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateUser(values);
    },
    enableReinitialize: true,
  });

  const handleGoogleCalendarInitiation = async () => {
    if (!user.isGoogleOAuthActivated) {
      return await handleGoToGoogleOAuth();
    }
    await initiateGoogleCalendar();
  };

  const handleGoToGoogleOAuth = async () => {
    const url = await getGoogleOAuthUrl();
    window.location.href = url;
  };

  const handleInitiateGoogleDrive = async () => {
    if (!user.isGoogleOAuthActivated) {
      return await handleGoToGoogleOAuth();
    }
    await initiateGoogleDrive();
  };

  const iframeLink = `<iframe loading="lazy" src="https://app.klaq.io/embedded-form?token=${user.id}" width="100%" height="540" frameborder="0" marginheight="0" marginwidth="0"><br /></iframe>`;
  const helpIframeLink = `https://tidycal.com/klaq/help10`;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(iframeLink);
    toast.custom(
      <ToastNotification
        messageId="toast.info.copy-clipboard.message"
        titleId="toast.info.copy-clipboard.title"
        status="info"
      />,
      { duration: 1500, position: 'top-right' },
    );
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(PATHS.SETTINGS);
  };

  return (
    <PageLayout>
      <div className="space-y-8 divide-y divide-gray-900/10">
        <div>
          <Button
            type="button"
            variant="text"
            color="secondary"
            leadingIcon={
              <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            }
            onClick={handlePrevious}
          >
            {intl.formatMessage({
              id: 'customers.customer-details.button.previous',
            })}
          </Button>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
          <div className="px-4 sm:px-0">
            <Typography
              variant="h2"
              className="text-base font-semibold leading-7 text-gray-900"
            >
              {intl.formatMessage({
                id: 'settings.enquiry.header',
              })}
            </Typography>
            <Typography
              variant="body"
              className="mt-1 text-sm leading-6 text-gray-600"
            >
              {intl.formatMessage({
                id: 'settings.enquiry.description',
              })}
            </Typography>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                  <Typography
                    variant="h3"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {intl.formatMessage({
                      id: 'settings.enquiry.form.header',
                    })}
                  </Typography>
                  <Typography
                    variant="body"
                    className="mt-2 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage({
                      id: 'settings.enquiry.form.description',
                    })}
                  </Typography>
                </div>
                <div className="sm:col-span-full">
                  <textarea
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-klaq-600 sm:text-sm sm:leading-6"
                    rows={3}
                    value={iframeLink}
                    disabled
                  />
                </div>
                <div className="sm:col-span-full flex space-x-4">
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="lg"
                    leadingIcon={<DocumentDuplicateIcon className="h-5 w-5" />}
                    onClick={handleCopyCode}
                  >
                    Copier le code
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    size="lg"
                    leadingIcon={<QuestionMarkCircleIcon className="h-5 w-5" />}
                    onClick={() => window.open(helpIframeLink, '_blank')}
                  >
                    J'ai besoin d'aide pour intégrer mon formulaire
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <span className="flex justify-between">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                {intl.formatMessage({
                  id: 'settings.automation.header',
                })}
              </h2>
              <BetaBadge />
            </span>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {intl.formatMessage({
                id: 'settings.automation.description',
              })}
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          >
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                  <Typography
                    variant="body"
                    className="mt-2 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage({
                      id: 'settings.social.no-social-login.description',
                    })}
                  </Typography>
                </div>
                <div className="sm:col-span-3">
                  {!isFetchingUser && user ? (
                    <IntegrationButton
                      Icon={GoogleIcon}
                      header={intl.formatMessage({
                        id: 'settings.social.social-login.google.header',
                      })}
                      description={intl.formatMessage({
                        id: 'settings.social.social-login.google.description',
                      })}
                      isIntegrated={user.isGoogleOAuthActivated}
                      onClick={handleGoToGoogleOAuth}
                    />
                  ) : (
                    <Skeleton variant={'rounded'} width={'full'} height={'8'} />
                  )}
                </div>

                <div className="sm:col-span-3 relative">
                  <Tooltip text="Prochainement" position="top">
                    <IntegrationButton
                      Icon={OutlookIcon}
                      isDisabled={true}
                      header={intl.formatMessage({
                        id: 'settings.social.social-login.outlook.header',
                      })}
                      description={intl.formatMessage({
                        id: 'settings.social.social-login.outlook.description',
                      })}
                      isIntegrated={false}
                      onClick={() => {
                        return;
                      }}
                    />
                  </Tooltip>
                </div>
                <div className="sm:col-span-full">
                  <Typography
                    variant="body"
                    className="mt-2 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage({
                      id: 'settings.storage.storage-manager.description',
                    })}
                  </Typography>
                </div>

                <div className="sm:col-span-3">
                  <IntegrationButton
                    isLoading={isInitiatingGoogleDrive}
                    Icon={GoogleDriveIcon}
                    onClick={handleInitiateGoogleDrive}
                    header={intl.formatMessage({
                      id: 'settings.storage.storage-manager.google-drive.header',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.storage.storage-manager.google-drive.description',
                    })}
                    isIntegrated={user.isGoogleDriveActivated}
                  />
                </div>
                <div className="sm:col-span-3 relative">
                  <Tooltip text="Prochainement" position="top">
                    <IntegrationButton
                      isDisabled={true}
                      Icon={DropboxIcon}
                      onClick={() => {
                        return;
                      }}
                      header={intl.formatMessage({
                        id: 'settings.storage.storage-manager.dropbox.header',
                      })}
                      description={intl.formatMessage({
                        id: 'settings.storage.storage-manager.dropbox.description',
                      })}
                      isIntegrated={false}
                    />
                  </Tooltip>
                </div>
                <div className="sm:col-span-full">
                  <Typography
                    variant="body"
                    className="mt-2 text-sm leading-6 text-gray-600"
                  >
                    {intl.formatMessage({
                      id: 'settings.calendar.provider.description',
                    })}
                  </Typography>
                </div>
                <div className="sm:col-span-3">
                  <IntegrationButton
                    isLoading={isInitiatingGoogleCalendar}
                    Icon={GoogleCalendarIcon}
                    onClick={handleGoogleCalendarInitiation}
                    header={intl.formatMessage({
                      id: 'settings.calendar.google.header',
                    })}
                    description={intl.formatMessage({
                      id: 'settings.calendar.google.description',
                    })}
                    isIntegrated={user.isGoogleCalendarActivated}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div></div>
      </div>
    </PageLayout>
  );
};
