import {
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { EyeIcon as EyeIconOutline } from '@heroicons/react/24/outline';
import { Button } from 'components/Button';
import { InvoiceBadge } from 'components/Invoice';
import { QuoteBadge } from 'components/Quote';
import { Typography } from 'components/Typography';

import { KebabMenu } from 'components';
import { Invoice } from 'interface/invoice/invoice.interface';
import { Quote } from 'interface/quote/quote.interface';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { getInvoiceSubtotal } from 'utils/invoice';
import { getSubtotalForQuote } from 'utils/quote';

type DocumentType = 'quote' | 'invoice';

const DOCUMENT_TYPE = {
  quote: 'quote',
  invoice: 'invoice',
};

type BillingDocumentProps = {
  documents?: Invoice[] | Quote[];
  type: DocumentType;
};

export const BillingDocumentList = (props: BillingDocumentProps) => {
  const { documents, type } = props;
  const intl = useIntl();
  const navigate = useNavigate();

  const handleDocumentDetails = (document: Invoice | Quote) => {
    const documentType =
      type === DOCUMENT_TYPE.quote ? PATHS.QUOTE : PATHS.INVOICE;
    navigate(`${documentType}/${document.id}/details`);
  };
  const handleQuoteToInvoice = (quote: Quote) => {
    navigate(`${PATHS.INVOICE_GENERATE}?fromQuote=${quote.id}`);
  };

  const handleViewQuote = (quote: Quote) => {
    navigate(`${PATHS.QUOTE}/${quote.id}/details`);
  };

  const handleDuplicateQuote = (quote: Quote) => {
    navigate(`${PATHS.QUOTE_GENERATE}?fromQuote=${quote.id}`);
  };

  const quoteMenu = (quote: Quote) => [
    {
      name: 'quote.list.menu.view',
      icon: EyeIconOutline,
      onClick: () => handleViewQuote(quote),
    },
    {
      name: 'quote.list.menu.transform-to-invoice',
      icon: ClipboardDocumentCheckIcon,
      onClick: () => handleQuoteToInvoice(quote),
    },
    {
      name: 'quote.list.menu.duplicate',
      icon: DocumentDuplicateIcon,
      onClick: () => handleDuplicateQuote(quote),
    },
  ];

  return documents && documents.length ? (
    <ul
      role="list"
      className="bg-white mt-2 divide-y divide-gray-100 rounded-md border border-gray-200 w-full"
    >
      {documents.map((document, idx) => (
        <li
          key={`${document.number}-${idx}`}
          className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
        >
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <div className="ml-4 flex min-w-0 flex-1 gap-2 items-center">
              <Typography
                variant="body"
                className="truncate font-medium text-gray-900"
              >
                {document.number}
              </Typography>
              <Typography
                variant="subtitle"
                className="flex-shrink-0 font-normal text-gray-400"
              >
                {type === DOCUMENT_TYPE.quote
                  ? getSubtotalForQuote(document as Quote).toFixed(2)
                  : getInvoiceSubtotal(document as Invoice).toFixed(2)}{' '}
                €
              </Typography>
            </div>

            <div className="ml-4 flex space-x-4 items-center">
              <Typography variant="subtitle">
                {type === DOCUMENT_TYPE.quote ? (
                  <QuoteBadge status={(document as Quote).status} />
                ) : (
                  <InvoiceBadge status={(document as Invoice).status} />
                )}
              </Typography>
              <Typography variant="subtitle">
                {document.isOpen ? (
                  <EyeIcon className="h-5 w-5 text-green-500" />
                ) : (
                  <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                )}
              </Typography>
              {type === DOCUMENT_TYPE.quote ? (
                <KebabMenu
                  items={quoteMenu(document as Quote)}
                  buttonSize="md"
                />
              ) : (
                <Button
                  type="button"
                  variant="link"
                  color="primary"
                  onClick={() => handleDocumentDetails(document)}
                >
                  {intl.formatMessage({
                    id: 'customers.customer-details.button.view',
                  })}
                </Button>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-klaq-500 focus:ring-offset-2">
      <DocumentIcon
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      />

      <Typography
        variant="h3"
        className="mt-2 text-sm font-semibold text-gray-900"
      >
        {intl.formatMessage({
          id: 'customers.customer-details.no-documents-found.header',
        })}
      </Typography>
      <Typography variant="body" className="mt-1 text-sm text-gray-500">
        {intl.formatMessage({
          id: 'customers.customer-details.no-documents-found.description',
        })}
      </Typography>
    </div>
  );
};
