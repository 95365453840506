import { Typography } from 'components/Typography';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import KlaqSubscriptionLogo from 'assets/klaq-subscription-logo.png';
import { Button } from 'components/Button';
import { add } from 'date-fns';
import { SubscriptionPlan } from 'enum/subscription-plan.enum';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/Login/selectors';
import { User } from 'interface/user/user.interface';
import { usePortalSession } from 'redux/Stripe/hooks';
import { SubscriptionPopup } from './SubscriptionPopup';

type SubscriptionCardProps = {
  user: User;
  hasActionRequired: boolean;
};

export const ManageSubscriptionCard = (props: SubscriptionCardProps) => {
  const { user, hasActionRequired } = props;
  const intl = useIntl();

  const [, openPortalSession] = usePortalSession();
  return (
    <div className="flex space-x-4 w-full h-full items-center">
      <img
        src={KlaqSubscriptionLogo}
        className="object-cover rounded-md w-48 h-48"
      />
      <div className="flex flex-col space-y-4 px-4 py-5 sm:p-6 bg-gray-50 rounded-md w-full">
        <Typography
          variant="h2"
          className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight"
        >
          {intl.formatMessage({
            id: 'subscription.header',
          })}
        </Typography>
        <Typography
          variant="body"
          className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"
        >
          {intl.formatMessage(
            {
              id: 'subscription.description',
            },
            {
              plan:
                user.subscriptionPlan &&
                user.subscriptionPlan === SubscriptionPlan.MONTHLY
                  ? 'mensuel'
                  : 'annuel',
            },
          )}
        </Typography>
        <Typography variant="subtitle">
          <Button
            variant="contained"
            color="primary"
            onClick={openPortalSession}
            leadingIcon={
              hasActionRequired && (
                <ExclamationTriangleIcon className="h-5 w-5" />
              )
            }
          >
            {intl.formatMessage({
              id: `subscription.button.${hasActionRequired ? 'action-required' : 'manage'}`,
            })}
          </Button>
        </Typography>
      </div>
    </div>
  );
};

type CreateSubscriptionCardProps = {
  user: User;
};

export const CreateSubscriptionCard = (props: CreateSubscriptionCardProps) => {
  const { user } = props;
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);

  const TRIAL_DAYS = 31;
  const endTrialDate = add(new Date(user.createdAt), { days: TRIAL_DAYS });

  //   const elapsedTime = differenceInDays(new Date(), new Date(user.createdAt));
  //   const isEligibleForTrial = elapsedTime <= TRIAL_DAYS;
  return (
    <>
      <div className="flex space-x-4 w-full h-full items-center">
        <img
          src={KlaqSubscriptionLogo}
          className="object-cover rounded-md w-48 h-48"
        />
        <div className="flex flex-col space-y-4 px-4 py-5 sm:p-6 bg-gray-50 rounded-md w-full">
          <Typography
            variant="h2"
            className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight"
          >
            {intl.formatMessage({
              id: 'subscription.trial.header',
            })}
          </Typography>
          <Typography
            variant="body"
            className="mt-1 max-w-2xl text-sm leading-6 text-gray-500"
          >
            {intl.formatMessage(
              {
                id: 'subscription.trial.description',
              },
              {
                date: endTrialDate.toLocaleDateString(),
              },
            )}
          </Typography>
          <Typography variant="subtitle">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              {intl.formatMessage({
                id: 'subscription.button.subscribe',
              })}
            </Button>
          </Typography>
        </div>
      </div>
      <SubscriptionPopup isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export const SubscriptionCard = () => {
  const user = useSelector(getUser);

  return (
    <>
      {user.subscriptionPlan && user.subscriptionStatus ? (
        <ManageSubscriptionCard
          user={user}
          hasActionRequired={!(user.subscriptionStatus === 'active')}
        />
      ) : (
        <CreateSubscriptionCard user={user} />
      )}
    </>
  );
};
