import { CustomerType } from 'enum/customer-type.enum';
import * as Yup from 'yup';

export const initialValues = {
  type: CustomerType.COMPANY,
  name: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  address: '',
  zipcode: '',
  city: '',
  country: '',
  legalVATNumber: '',
  legalRegistrationNumber: '',
  headcount: '',
  notes: '',
  birthdayDate: '',
  phoneLandline: '',
};

export const validationSchema = Yup.object()
  .shape({
    type: Yup.mixed().oneOf(Object.values(CustomerType)).required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    phone: Yup.string().matches(
      /^[0-9]+$/,
      'Phone number must contain only numbers',
    ),
    email: Yup.string().email('Invalid email'),
  })
  .test({
    name: 'either-email-or-phone',
    exclusive: true,
    test: (values) => {
      const { email, phone } = values;
      return !!email || !!phone;
    },
    message: 'Either email or phone must be specified',
  });
