import { DocumentCheckIcon } from '@heroicons/react/24/solid';
import { Typography } from 'components/Typography';
import { useIntl } from 'react-intl';
import { classNames } from 'utils/utils';

type Props = {
  className?: string;
  documentId?: string;
};

export const UploadedDocument = (props: Props) => {
  const intl = useIntl();
  const { className } = props;

  return (
    <div
      className={classNames(
        'flex justify-center rounded-lg border-dashed border-gray-900/25 px-6 py-10 flex flex-col',
        'border-2',
        className,
      )}
    >
      <div className="flex flex-col items-center text-center pb-2">
        <DocumentCheckIcon
          className="mx-auto h-12 w-12 text-success-300"
          aria-hidden="true"
        />
        <div className="mt-4 flex text-sm leading-6 text-gray-600">
          <Typography variant="body" className="pl-1">
            {intl.formatMessage({
              id: 'documents.downloaded-successfully',
            })}
          </Typography>
        </div>
      </div>
    </div>
  );
};
