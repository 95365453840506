import {
  ChatBubbleLeftEllipsisIcon,
  ChevronUpDownIcon,
  DocumentCheckIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { Typography } from 'components';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { classNames } from 'utils/utils';
import { SECTION } from './section.enum';

export const Navigation = () => {
  const intl = useIntl();
  const [query, setQuery] = useSearchParams();
  const selectedSection = query.get('section') || SECTION.ASSISTANT;

  const secondaryNavigation = [
    {
      section: SECTION.ASSISTANT,
      icon: MagnifyingGlassIcon,
      current: selectedSection === SECTION.ASSISTANT,
    },
    {
      section: SECTION.ACTIONS,
      icon: ChevronUpDownIcon,
      current: selectedSection === SECTION.ACTIONS,
    },
    // {
    //   section: SECTION.PRODUCTS,
    //   icon: ShoppingBagIcon,
    //   current: selectedSection === SECTION.PRODUCTS,
    // },
    {
      section: SECTION.BILLING,
      icon: DocumentCheckIcon,
      current: selectedSection === SECTION.BILLING,
    },
    // {
    //   section: SECTION.NOTES,
    //   icon: ChatBubbleLeftIcon,
    //   current: selectedSection === SECTION.NOTES,
    // },
    {
      section: SECTION.LOGS,
      icon: ChatBubbleLeftEllipsisIcon,
      current: selectedSection === SECTION.LOGS,
    },
  ];

  useEffect(() => {
    setQuery({ section: selectedSection });
  }, [selectedSection]);

  return (
    <div className="hidden sm:block">
      <div className="border-b border-gray-900/10">
        <nav className="flex space-x-8" aria-label="Tabs">
          {secondaryNavigation.map((tab) => (
            <a
              key={tab.section}
              onClick={() => setQuery({ section: tab.section })}
              className={classNames(
                tab.current
                  ? 'border-klaq-500 text-klaq-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium',
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <tab.icon
                className={classNames(
                  tab.current
                    ? 'text-klaq-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5',
                )}
                aria-hidden="true"
              />
              <Typography
                variant="body"
                className={classNames(
                  tab.current
                    ? 'text-klaq-500'
                    : 'text-gray-500 group-hover:text-gray-600',
                )}
              >
                {intl.formatMessage({
                  id: `event-details.secondary-navigation.${tab.section}`,
                })}
              </Typography>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};
