import { useSearchParams } from 'react-router-dom';
import { MainEventDetailsPageProps } from './EventDetailsPage';
import {
  ActionSection,
  AssistantSection,
  BillingSection,
  LogsSection,
  Navigation,
  SECTION,
} from './Sections';

export const EventDetailsBody = (props: MainEventDetailsPageProps) => {
  const { event } = props;

  const [query] = useSearchParams();
  const selectedSection = query.get('section') || SECTION.ASSISTANT;

  return (
    <>
      <Navigation />
      {selectedSection === SECTION.BILLING && <BillingSection {...event} />}

      {selectedSection === SECTION.LOGS && <LogsSection />}
      {/* {selectedSection === SECTION.PRODUCTS && <ProductsSection {...event} />} */}
      {selectedSection === SECTION.ACTIONS && <ActionSection {...event} />}
      {selectedSection === SECTION.ASSISTANT && <AssistantSection {...event} />}
    </>
  );
};
