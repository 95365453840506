export const getProductPriceWithTaxes = (
  price: number | string,
  vtaRate: string,
) => {
  return Number(price) + Number(price) * (Number(vtaRate) / 100);
};

export const getProductPriceWithoutTaxes = (
  priceWithTaxes: number | string,
  vtaRate: string,
) => {
  return Number(priceWithTaxes) / (1 + Number(vtaRate) / 100);
};
