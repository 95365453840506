import { SubscriptionPopup } from 'components';
import { OnboardingLayout } from 'layouts/OnboardingLayout/OnboardingLayout';

export const SubscriptionPage = () => {
  return (
    <OnboardingLayout>
      <SubscriptionPopup
        isOpen={true}
        setOpen={() => {
          return;
        }}
      />
    </OnboardingLayout>
  );
};
