import { Typography } from 'components/Typography';
type MailTemplateProps = {
  subject?: string;
  content?: string;
  logoUrl?: string;
};

export const MailTemplate = (props: MailTemplateProps) => {
  const { subject, content, logoUrl } = props;

  return (
    <div className="bg-f6f9fc font-sans ring-1 ring-gray-300 p-4 rounded-md">
      <table
        className="mx-auto max-w-37.5em bg-white py-5 px-0 md:px-48 mb-16"
        align="center"
        width="100%"
        border={0}
        cellPadding={0}
        cellSpacing={0}
        role="presentation"
      >
        <tbody>
          <tr className="w-full">
            <td>
              <table
                className="w-full mx-auto"
                align="center"
                width="100%"
                border={0}
                cellPadding={0}
                cellSpacing={0}
                role="presentation"
              >
                <tbody>
                  <tr>
                    <td>
                      {logoUrl ? (
                        <img
                          width={60}
                          src={logoUrl}
                          className="mx-auto block outline-none border-none"
                          alt="Logo"
                        />
                      ) : (
                        ''
                      )}
                      <Typography
                        variant="body"
                        className="text-base leading-6 my-4 text-gray-700"
                      >
                        {subject} via{' '}
                        <a
                          className="text-klaq-600"
                          href="https://klaq.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Klaq
                        </a>
                      </Typography>
                      <hr className="w-full border-t border-gray-300 my-5" />
                      <Typography
                        variant="body"
                        className="text-base leading-6 my-4 text-gray-700 whitespace-pre-line"
                      >
                        {content}
                      </Typography>
                      <a
                        className="block w-full py-2 px-4 bg-klaq-600 rounded text-white text-center font-bold"
                        target="_blank"
                      >
                        <Typography
                          variant="subtitle"
                          className="text-white text-center font-bold text-base"
                        >
                          Voir mon document
                        </Typography>
                      </a>
                      <hr className="w-full border-t border-gray-300 my-5" />
                      <Typography
                        variant="body"
                        className="text-sm leading-4 my-4 text-gray-500"
                      >
                        Powered by{' '}
                        <a
                          className="text-klaq-600"
                          href="https://klaq.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Klaq
                        </a>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
