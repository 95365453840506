export enum PerformingCategory {
  DJ = 'DJ',
  SINGER = 'SINGER',
  MUSICIAN = 'MUSICIAN',
  MUSIC_BAND = 'MUSIC_BAND',
  DANCER = 'DANCER',
  HUMORIST = 'HUMORIST',
  COMEDIAN = 'COMEDIAN',
  MAGICIAN = 'MAGICIAN',
  CLOWN = 'CLOWN',
  EVENT_AGENCY = 'EVENT_AGENCY',
  JUGGLER = 'JUGGLER',
  FLORIST = 'FLORIST',
  CARTOONIST = 'CARTOONIST',
  SPEAKER = 'SPEAKER',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  VIDEOGRAPHER = 'VIDEOGRAPHER',
  MAKEUP_ARTIST = 'MAKEUP_ARTIST',
  HAIR_STYLIST = 'HAIR_STYLIST',
  CATERER = 'CATERER',
  BARTENDER = 'BARTENDER',
  WAITER = 'WAITER',
  CAR_RENTAL = 'CAR_RENTAL',
  PHOTO_BOOTH = 'PHOTO_BOOTH',
  PARTY_RENTAL = 'PARTY_RENTAL',
  DRAG_QUEEN = 'DRAG_QUEEN',
  WOOD_TOY = 'WOOD_TOY',
  BALLOON_ARTIST = 'BALLOON_ARTIST',
  FIREWORKS = 'FIREWORKS',
  FIRE_EATER = 'FIRE_EATER',
  ACROBAT = 'ACROBAT',
  OTHER = 'OTHER',
}
