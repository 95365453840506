import { Bars3Icon } from '@heroicons/react/24/outline';
import { getUser } from 'redux/Login/selectors';
import { useFetchUser } from 'redux/Login/hooks';
import { useSelector } from 'react-redux';
import { NotificationWidget } from 'components/Notifications';
import { useEffect } from 'react';
import { PATHS } from 'routes';
import { useIntl } from 'react-intl';
import { Typography } from 'components/Typography';

type NavbarProps = {
  onSidebarToggle: () => void;
};

export const Navbar = (props: NavbarProps) => {
  const { onSidebarToggle } = props;
  const [, fetchUser] = useFetchUser();
  const user = useSelector(getUser);
  const intl = useIntl();

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-klaq-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-klaq-200 lg:hidden"
        onClick={onSidebarToggle}
      >
        <Typography variant="body" className="sr-only">
          Open sidebar
        </Typography>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 text-sm font-semibold leading-6 text-white">
        {`/${window.location.pathname.split('/')[1]}` === PATHS.DASHBOARD
          ? intl.formatMessage({ id: 'sidebar.dashboard' })
          : `/${window.location.pathname.split('/')[1]}` === PATHS.EVENTS
            ? intl.formatMessage({ id: 'sidebar.events' })
            : `/${window.location.pathname.split('/')[1]}` === PATHS.CUSTOMERS
              ? intl.formatMessage({ id: 'sidebar.customers' })
              : `/${window.location.pathname.split('/')[1]}` === PATHS.QUOTES
                ? intl.formatMessage({ id: 'sidebar.quotes' })
                : `/${window.location.pathname.split('/')[1]}` ===
                    PATHS.INVOICES
                  ? intl.formatMessage({ id: 'sidebar.invoices' })
                  : `/${window.location.pathname.split('/')[1]}` ===
                      PATHS.PRODUCTS
                    ? intl.formatMessage({ id: 'sidebar.products' })
                    : ''}
      </div>
      <NotificationWidget />
      <a href={PATHS.PROFILE}>
        <img
          className="h-8 w-8 rounded-full bg-klaq-700"
          src={user.logoUrl}
          alt=""
        />
      </a>
    </div>
  );
};
