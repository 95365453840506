import { Dialog, Transition } from '@headlessui/react';
import { Typography } from 'components/Typography';
import { KLAQ_COLORS } from 'constants/colors';
import { SubscriptionPlan } from 'enum/subscription-plan.enum';
import { Fragment } from 'react';
import { useCreateSubscription } from 'redux/Stripe/hooks';

type SubscriptionPopupProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

export const SubscriptionPopup = (props: SubscriptionPopupProps) => {
  const { isOpen, setOpen } = props;

  const [, createSubscription] = useCreateSubscription();

  const tiers = [
    {
      name: 'Mensuel',
      id: 'tier-monthly',
      onClick: () => createSubscription(SubscriptionPlan.MONTHLY),
      priceMonthly: '60€',
      priceMonthlyNoTax: '50€',
      description:
        "Pour les organisateurs d'événements flexibles qui apprécient la liberté et la souplesse, l'option d'abonnement mensuel est idéale. Bénéficiez de tous les avantages de notre logiciel de gestion événementielle sans engagement à long terme. Commencez dès maintenant et annulez quand vous le souhaitez.",

      features: [
        'Clients et événenements illimités',
        'Synchronisation avec votre calendrier',
        'Formulaire de contact intégré à votre site',
        'Devis et facturation',
        'Paiement en ligne',
        'Relance des factures impayées',
        'Génération de feuille de route',
        'Calcul des temps de trajet',
        'Automatisation',
        "Outils d'analyses",
        'Suppression des mentions "Klaq"',
        'Modèle de mails et de contrats',
        'Portail Client',
        'Signature électronique',
      ],
    },
    {
      name: 'Annuel (-20%)',
      id: 'tier-annual',
      onClick: () => createSubscription(SubscriptionPlan.YEARLY),
      priceMonthly: '50€',
      priceMonthlyNoTax: '41,67€',
      description:
        "Pour les planificateurs d'événements visionnaires qui cherchent à maximiser leur efficacité et leurs économies, notre abonnement annuel est la solution ultime. En vous engageant sur une année complète, vous bénéficiez d'une réduction significative sur le prix mensuel !",
      features: [
        'Clients et événenements illimités',
        'Synchronisation avec votre calendrier',
        'Formulaire de contact intégré à votre site',
        'Devis et facturation',
        'Paiement en ligne',
        'Relance des factures impayées',
        'Génération de feuille de route',
        'Calcul des temps de trajet',
        'Automatisation',
        "Outils d'analyses",
        'Suppression des mentions "Klaq"',
        'Modèle de mails et de contrats',
        'Portail Client',
        'Signature électronique',
        'Support prioritaire',
      ],
    },
  ];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-lg">
                <div className="isolate overflow-hidden bg-gray-900">
                  <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
                    <div className="mx-auto max-w-4xl">
                      <Typography
                        variant="h2"
                        className="text-base font-semibold leading-7 text-klaq-400"
                      >
                        Abonnement
                      </Typography>
                      <Typography
                        variant="body"
                        className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl"
                      >
                        Klaq Pro, <br className="hidden sm:inline lg:hidden" />
                        un abonnement unique
                      </Typography>
                      <Typography
                        variant="subtitle"
                        className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl"
                      >
                        Klaq Pro
                      </Typography>
                    </div>
                    <div className="relative mt-6">
                      <Typography
                        variant="body"
                        className="mx-auto max-w-2xl text-lg leading-8 text-white/60"
                      >
                        Centralisez la coordination de vos événements pour une
                        gestion efficace. Simplifiez votre quotidien grâce à un
                        pilotage automatique pour votre entreprise. Tout ce dont
                        vous avez besoin pour créer une expérience client
                        exceptionnelle.
                      </Typography>
                      <svg
                        viewBox="0 0 1208 1024"
                        className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
                      >
                        <ellipse
                          cx={604}
                          cy={512}
                          fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
                          rx={604}
                          ry={512}
                        />
                        <defs>
                          <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                            <stop stopColor={KLAQ_COLORS[500]} />
                            <stop offset={1} stopColor={KLAQ_COLORS[800]} />
                          </radialGradient>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div className="flow-root bg-white pb-24 sm:pb-32">
                    <div className="-mt-80">
                      <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
                          {tiers.map((tier) => (
                            <div
                              key={tier.id}
                              className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                            >
                              <div id={tier.id}>
                                <Typography
                                  variant="h3"
                                  className="text-base font-semibold leading-7 text-klaq-600"
                                >
                                  {tier.name}
                                </Typography>
                                <div className="mt-4 flex items-baseline gap-x-2">
                                  <Typography
                                    variant="subtitle"
                                    className="text-5xl font-bold tracking-tight text-gray-900"
                                  >
                                    {tier.priceMonthly}
                                  </Typography>
                                  <Typography
                                    variant="subtitle"
                                    className="text-base font-semibold leading-7 text-gray-600"
                                  >
                                    / mois
                                  </Typography>
                                </div>
                                <Typography
                                  variant="body"
                                  className="mt-6 text-base leading-7 text-gray-600"
                                >
                                  {tier.description}
                                </Typography>
                                <Typography
                                  variant="subtitle"
                                  className="mt-2 text-sm leading-6 text-gray-500"
                                >
                                  soit {tier.priceMonthlyNoTax} H.T.
                                </Typography>
                                <button
                                  onClick={tier.onClick}
                                  aria-describedby={tier.id}
                                  className="mt-8 w-full block rounded-md bg-klaq-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-klaq-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-klaq-600"
                                >
                                  Passer à Klaq Pro
                                </button>
                                <p className="mt-6 text-base leading-7 text-gray-600">
                                  {tier.description}
                                </p>

                                {/* <ul
                                  role="list"
                                  className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                                >
                                  {tier.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                      <CheckIcon
                                        className="h-6 w-5 flex-none text-klaq-600"
                                        aria-hidden="true"
                                      />
                                      {feature}
                                    </li>
                                  ))}
                                </ul> */}
                              </div>
                            </div>
                          ))}
                          {/* DISCOUNT */}
                          {/* <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                            <div className="lg:min-w-0 lg:flex-1">
                              <Typography variant='h3' className="text-lg font-semibold leading-8 tracking-tight text-klaq-600">
                                Discounted
                              </Typography>
                              <Typography variant='body' className="mt-1 text-base leading-7 text-gray-600">
                                Dolor dolores repudiandae doloribus. Rerum sunt
                                aut eum. Odit omnis non voluptatem sunt eos
                                nostrum.
                              </Typography>
                            </div>
                            <a
                              href="#"
                              className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-klaq-600 ring-1 ring-inset ring-klaq-200 hover:ring-klaq-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-klaq-600"
                            >
                              Buy discounted license{' '}
                              <Typography variant='subtitle' aria-hidden="true">&rarr;</Typography>
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
