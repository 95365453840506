import { Typography } from 'components/Typography';
import { Dialog, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { Button } from 'components/Button';
import { add, differenceInDays } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchUser } from 'redux/Login/hooks';
import { getUser } from 'redux/Login/selectors';
import { usePortalSession } from 'redux/Stripe/hooks';
import { PATHS } from 'routes';
import { handleClickHelp } from 'utils/clickOnCrisp';

export const UpgradeNow = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useSelector(getUser);

  const [isOpen, setOpen] = useState(false);
  const [{ isLoading: isLoadingPortal }, getPortalSession] = usePortalSession();

  const [{ isLoading }, fetchUser] = useFetchUser();
  // * https://docs.stripe.com/api/subscriptions/object#subscription_object-status
  const ACCEPTED_STATUSES = ['active', 'trialing'];

  const TRIAL_DAYS = 31;
  const endTrialDate = add(new Date(user.createdAt), { days: TRIAL_DAYS });
  const elapsedTime = differenceInDays(new Date(), endTrialDate);
  const isEligibleForTrial = elapsedTime <= 0;

  useEffect(() => {
    const checkUserSubscription = async () => {
      const data = await fetchUser();
      if (!data) return;

      if (data.subscriptionPlan && data.subscriptionStatus === 'active') {
        setOpen(false);
        return;
      }

      if (!isEligibleForTrial && !data.subscriptionPlan) {
        setOpen(true);
      }

      if (
        data.subscriptionStatus &&
        !ACCEPTED_STATUSES.includes(data.subscriptionStatus)
      ) {
        setOpen(true);
      }
    };
    if (isEligibleForTrial) {
      setOpen(false);
      return;
    } else {
      checkUserSubscription();
    }
  }, []);

  return (
    <>
      {!isEligibleForTrial && !isLoading && user && (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20"
            onClose={() => {
              return;
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-95 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-klaq-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-klaq-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {user.subscriptionPlan
                              ? intl.formatMessage({
                                  id: 'subscription.billing-error.header',
                                })
                              : intl.formatMessage({
                                  id: 'subscription.upgrade-now.header',
                                })}
                          </Dialog.Title>
                          <div className="mt-2">
                            <Typography
                              variant="body"
                              className="text-sm text-gray-500"
                            >
                              {user.subscriptionPlan
                                ? intl.formatMessage({
                                    id: 'subscription.billing-error.description',
                                  })
                                : intl.formatMessage({
                                    id: 'subscription.upgrade-now.description',
                                  })}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <Typography
                        variant="subtitle"
                        className="flex flex-row space-x-4"
                      >
                        <Button
                          type="button"
                          onClick={handleClickHelp}
                          variant="contained"
                          color="secondary"
                          leadingIcon={
                            <QuestionMarkCircleIcon className="h-5 w-5" />
                          }
                        >
                          {intl.formatMessage({
                            id: 'subscription.upgrade-now.button.support',
                          })}
                        </Button>
                        <Typography
                          variant="subtitle"
                          className="inline-block relative"
                        >
                          {user.subscriptionPlan ? (
                            <Button
                              onClick={getPortalSession}
                              variant="contained"
                              color="primary"
                              trailingIcon={
                                <ArrowRightIcon className="h-5 w-5" />
                              }
                              isLoading={isLoadingPortal}
                            >
                              {intl.formatMessage({
                                id: 'subscription.billing-error.button.manage',
                              })}
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              onClick={() => navigate(PATHS.SUBSCRIBE)}
                              variant="contained"
                              color="primary"
                              trailingIcon={
                                <ArrowRightIcon className="h-5 w-5" />
                              }
                            >
                              {intl.formatMessage({
                                id: 'subscription.upgrade-now.button.upgrade',
                              })}
                            </Button>
                          )}
                          <span className="absolute right-0 top-0 block h-2 w-2 rounded-full bg-klaq-400 ring-2 ring-white animate-ping"></span>
                        </Typography>
                      </Typography>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};
