import { useAsyncCallback } from '@react-hooks-library/core';
import { SubscriptionPlan } from 'enum/subscription-plan.enum';
import { KlaqToast } from 'utils/KlaqToast';
import webClient from 'utils/webclient';

export const useCreateSubscription = () => {
  return useAsyncCallback(async (subscriptionPlan: SubscriptionPlan) => {
    try {
      const { data } = await webClient.post('/subscription', {
        type: subscriptionPlan,
      });
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error: any) {
      const code = error.response.data.code
        ? error.response.data.code.toLowerCase()
        : null;
      KlaqToast('danger', code);
      console.error(error);
    }
  });
};

export const usePortalSession = () => {
  return useAsyncCallback(async () => {
    try {
      const { data } = await webClient.post('/subscription/portal-session');
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error: any) {
      const code = error.response.data.code
        ? error.response.data.code.toLowerCase()
        : null;
      KlaqToast('danger', code);
      console.error(error);
    }
  });
};
