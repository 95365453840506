import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../states';

export const commentarySlice = createSlice({
  name: 'commentary',
  initialState,
  reducers: {
    setCommentaries: (state, action) => {
      state.commentaries = action.payload;
    },
    setCommentary: (state, action) => {
      state.commentaries = [...state.commentaries, action.payload];
    },
  },
});

export const { setCommentary, setCommentaries } = commentarySlice.actions;

export default commentarySlice.reducer;
