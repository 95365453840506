import { Typography } from 'components/Typography';
import { Menu } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchNotifications } from 'redux/Notification/hooks';
import { getNotifications } from 'redux/Notification/selectors';
import { NotificationsSidePanel } from './NotificationsSidePanel';
import { NotificationCard } from './NotificationCard';

export const NotificationWidget = () => {
  const [unreadNotifications, setUnreadNotifications] = useState<number | null>(
    null,
  );
  const [isOpen, setIsOpened] = useState(false);

  const [, fetchNotifications] = useFetchNotifications();
  const notifications = useSelector(getNotifications);

  const handleInteraction = async () => {
    setIsOpened((isOpened) => !isOpened);

    if (!(unreadNotifications === null || unreadNotifications > 0)) {
      return;
    }
    await fetchNotifications();
  };

  useEffect(() => {
    const notificationSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/notification/count`,
      { withCredentials: true },
    );

    const onMessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);

      setUnreadNotifications((unreadNotifications) => {
        const nextCount = (unreadNotifications ?? 0) + data.unreadCount;
        return !nextCount ? null : data.unreadCount;
      });
    };
    notificationSource.addEventListener('message', onMessage);
    /* eslint-disable */
    return () => {
      notificationSource!.close();
      notificationSource!.removeEventListener('message', onMessage);
    };
    /* eslint-enable */
  }, []);

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className="flex items-center" onClick={handleInteraction}>
          <Typography variant="subtitle" className="inline-block relative">
            <BellIcon
              className="h-6 w-6 text-klaq-200 hover:text-white"
              aria-hidden="true"
            />
            {unreadNotifications && unreadNotifications > 0 ? (
              <span className="absolute right-0 top-0 block h-2 w-2 rounded-full bg-danger-500 ring-2 ring-white"></span>
            ) : null}
          </Typography>
        </Menu.Button>

        <NotificationsSidePanel isOpen={isOpen} setOpen={setIsOpened}>
          <ul className="mt-4 flex flex-col divide-y divide-gray-200 overflow-y-scroll">
            {notifications.map((notification) => (
              <li key={notification.id} className="py-4">
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  shouldShowContent={!!notification.content}
                />
              </li>
            ))}
          </ul>
        </NotificationsSidePanel>
      </Menu>
    </>
  );
};
