import {
  ArrowLeftIcon,
  CheckIcon,
  PaperClipIcon,
} from '@heroicons/react/24/solid';
import { Button, QuoteBadge, Typography, DefaultPopUp } from 'components';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  useFetchMainEvents,
  useUpdateMainEventStatus,
} from 'redux/MainEvent/hooks';
import { getMainEvent } from 'redux/MainEvent/selectors';
import { useUpdateQuoteStatus } from 'redux/Quote/hooks';
import { getSubtotalForQuote } from 'utils/quote';
import { initialValues } from './selectQuoteForm';
import { EventStatus } from 'enum/event-status.enum';
import { QuoteStatus } from 'enum/quote-status.enum';
import { Quote } from 'interface/quote/quote.interface';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  eventId: string;
  eventStatus: EventStatus;
};

export const EventBadgeSelectQuote = (props: Props) => {
  const { isOpen, setOpen, eventId, eventStatus } = props;
  const intl = useIntl();

  const getQuoteStatusFromEventStatus = (
    eventStatus: EventStatus,
  ): QuoteStatus => {
    switch (eventStatus) {
      case EventStatus.QUOTE_ACCEPTED:
        return QuoteStatus.ACCEPTED;
      case EventStatus.QUOTE_SENT:
        return QuoteStatus.SENT;
      case EventStatus.QUOTE_REJECTED:
        return QuoteStatus.REJECTED;
      default:
        throw new Error('Invalid event status');
    }
  };

  const mainEvent = useSelector((state: any) => getMainEvent(state, eventId));
  const quotes =
    mainEvent?.quotes?.map((quote: Quote) => ({
      ...quote,
      isChecked: quote.status === getQuoteStatusFromEventStatus(eventStatus),
    })) || [];
  const [{ isLoading: isUpdatingQuoteStatus }, updateQuoteStatus] =
    useUpdateQuoteStatus();
  const [{ isLoading: isUpdatingEventStatus }, updateEventStatus] =
    useUpdateMainEventStatus();
  const [{ isLoading: isFetchingMainEvents }, fetchMainEvents] =
    useFetchMainEvents();

  const quoteStatus = getQuoteStatusFromEventStatus(eventStatus);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      quotes: [...quotes],
    },
    onSubmit: async (values) => {
      await updateEventStatus({ status: eventStatus }, mainEvent.id);
      setOpen(false);
      const quotesToUpdate = values.quotes.filter((quote) => quote.isChecked);
      await Promise.all(
        quotesToUpdate.map(
          async (quote) =>
            await updateQuoteStatus(quote as unknown as Quote, quoteStatus),
        ),
      );
      await fetchMainEvents();
    },
    enableReinitialize: true,
  });

  return mainEvent.quotes && mainEvent.quotes.length > 0 ? (
    <DefaultPopUp isOpen={isOpen} setOpen={setOpen}>
      <form onSubmit={formik.handleSubmit}>
        <fieldset>
          <div className="flex space-x-4">
            <legend className="text-base font-semibold leading-6 text-gray-900">
              {intl.formatMessage({
                id: 'event-details.billing.select-quote.header',
              })}
            </legend>
            <QuoteBadge status={quoteStatus} />
          </div>
          <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
            {mainEvent.quotes &&
              mainEvent.quotes.length > 0 &&
              formik.values.quotes &&
              formik.values.quotes.length > 0 &&
              mainEvent.quotes.map((quote: Quote, index: number) => (
                <div key={quote.id} className="relative flex items-start py-4">
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2 items-center">
                      <Typography
                        variant="body"
                        className="truncate font-medium text-gray-900"
                      >
                        {quote.number}
                      </Typography>
                      <Typography
                        variant="subtitle"
                        className="flex-shrink-0 font-normal text-gray-400"
                      >
                        {getSubtotalForQuote(quote).toFixed(2)}€
                      </Typography>
                    </div>
                  </div>

                  <div className="ml-3 flex h-6 items-center">
                    <input
                      name={`quotes.${index}.isChecked`}
                      onChange={() => {
                        if (formik.values.quotes[index])
                          formik.setFieldValue(`quotes.${index}`, {
                            ...quote,
                            isChecked: !formik.values.quotes[index].isChecked,
                          });
                      }}
                      checked={
                        formik.values.quotes[index]
                          ? formik.values.quotes[index].isChecked
                          : false
                      }
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-klaq-600 focus:ring-klaq-600"
                    />
                  </div>
                </div>
              ))}
          </div>
        </fieldset>
        <div className="flex flex-row-reverse justify-between space-x-4 pt-4">
          <Button
            leadingIcon={<CheckIcon className="w-5 h-5" />}
            type="submit"
            variant="contained"
            color="primary"
            onClick={formik.submitForm}
            isLoading={
              isUpdatingQuoteStatus ||
              isUpdatingEventStatus ||
              isFetchingMainEvents
            }
          >
            {intl.formatMessage({
              id: 'event-details.billing.select-quote.button.change-status',
            })}
          </Button>
          <Button
            leadingIcon={<ArrowLeftIcon className="w-5 h-5" />}
            type="button"
            variant="link"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            {intl.formatMessage({
              id: 'event-details.billing.select-quote.button.cancel',
            })}
          </Button>
        </div>
      </form>
    </DefaultPopUp>
  ) : (
    <DefaultPopUp isOpen={isOpen} setOpen={setOpen}>
      <Typography variant="h1">
        {intl.formatMessage({
          id: 'event-details.billing.select-quote.no-quotes.header',
        })}
      </Typography>
      <Typography variant="body">
        {intl.formatMessage({
          id: 'event-details.billing.select-quote.no-quotes.description',
        })}
      </Typography>
      <div className="flex flex-row-reverse justify-between space-x-4 pt-4">
        <Button
          leadingIcon={<CheckIcon className="w-5 h-5" />}
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            updateEventStatus({ status: eventStatus }, eventId);
            setOpen(false);
          }}
          isLoading={isUpdatingEventStatus}
        >
          {intl.formatMessage({
            id: 'event-details.billing.select-quote.button.change-status',
          })}
        </Button>
        <Button
          leadingIcon={<ArrowLeftIcon className="w-5 h-5" />}
          type="button"
          variant="link"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          {intl.formatMessage({
            id: 'event-details.billing.select-quote.button.cancel',
          })}
        </Button>
      </div>
    </DefaultPopUp>
  );
};
