import { Typography } from 'components/Typography';
import { CloudArrowUpIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import { useId, useState } from 'react';
import { classNames, getHumanFileSize } from '../../utils/utils';
import { useIntl } from 'react-intl';

type UploadDocumentZoneProps = {
  fileList: File[];
  setFiles: (files: File[]) => void;
  className: string;
  maximumFiles: number;
  mimeTypeAllowed?: string[];
};

export const UploadDocumentZone = (props: UploadDocumentZoneProps) => {
  const { fileList, setFiles, className, mimeTypeAllowed, maximumFiles } =
    props;
  const intl = useIntl();

  const [shouldHighlight, setShouldHighlight] = useState(false);
  const preventDefaultHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleRemoveFile = (fileName: string) => {
    const newFiles = fileList.filter((f) => f.name !== fileName);
    setFiles(newFiles);
  };

  const id = useId();

  return (
    <div className={classNames(className)}>
      {fileList.length < maximumFiles && (
        <div
          className={classNames(
            'flex justify-center rounded-lg border-dashed border-gray-900/25 px-6 py-10 flex flex-col',
            shouldHighlight ? 'border-2 bg-gray-50' : 'border-2',
          )}
          onDragOver={(e) => {
            preventDefaultHandler(e);
            setShouldHighlight(true);
          }}
          onDragEnter={(e) => {
            preventDefaultHandler(e);
            setShouldHighlight(true);
          }}
          onDragLeave={(e) => {
            preventDefaultHandler(e);
            setShouldHighlight(false);
          }}
          onDrop={(e) => {
            preventDefaultHandler(e);
            const files = Array.from(e.dataTransfer.files);
            setFiles(files.concat(fileList));
            setShouldHighlight(false);
          }}
        >
          <div className="flex flex-col items-center text-center pb-2">
            <CloudArrowUpIcon
              className="mx-auto h-12 w-12 text-gray-300"
              aria-hidden="true"
            />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor={id}
                className="relative cursor-pointer rounded-md bg-white font-semibold text-klaq-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-klaq-600 focus-within:ring-offset-2 hover:text-klaq-500"
              >
                <Typography variant="body" className="text-klaq-600">
                  {intl.formatMessage({
                    id: 'documents.header.input',
                  })}
                </Typography>
                <input
                  id={id}
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept={mimeTypeAllowed?.join(',')}
                  onChange={(e) => {
                    if (!e.target.files) return;
                    const files = Array.from(e.target.files);
                    setFiles(files.concat(fileList));
                  }}
                />
              </label>
              <Typography variant="body" className="pl-1">
                {intl.formatMessage({
                  id: 'documents.header.drag-and-drop',
                })}
              </Typography>
            </div>
            <Typography variant="body">
              {intl.formatMessage(
                {
                  id: 'documents.description',
                },
                {
                  mimeTypeAllowed: mimeTypeAllowed?.join(', '),
                },
              )}
            </Typography>
          </div>
        </div>
      )}
      {fileList.length > 0 && (
        <div>
          <label className="mt-4 block text-sm font-medium leading-6 text-gray-900">
            {intl.formatMessage(
              {
                id: 'documents.label',
              },
              { s: fileList.length > 1 ? 's' : '' },
            )}
          </label>
          <ul
            role="list"
            className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200 w-full"
          >
            {fileList.map((file, idx) => (
              <li
                key={`${file.name}-${idx}`}
                className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
              >
                <div className="flex w-0 flex-1 items-center">
                  <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <Typography
                      variant="subtitle"
                      className="truncate font-medium"
                    >
                      {file.name}
                    </Typography>
                    <Typography
                      variant="subtitle"
                      className="flex-shrink-0 text-gray-400"
                    >
                      {getHumanFileSize(file.size)}
                    </Typography>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      className="font-medium text-klaq-600 hover:text-klaq-500"
                      onClick={() => handleRemoveFile(file.name)}
                    >
                      {intl.formatMessage({
                        id: 'documents.button.remove',
                      })}
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
