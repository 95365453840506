import * as Yup from 'yup';

export const settingsInitialValues = {
  isChangelogNotificationEnabled: true,
  isNewDealNotificationEnabled: true,
  isMarketingNotificationEnabled: true,
  timeZone: '(GMT+02:00)',
  isVHREnabled: true,
  pricePerKm: 0.5,
  isCustomFootNoteEnabled: true,
  customFootNote:
    "En cas de retard de paiement, des pénalités de retard seront exigibles conformément aux dispositions légales en vigueur, comprenant un taux d'intérêt légal ainsi qu'une indemnité forfaitaire pour frais de recouvrement, conformément aux articles L.441-6 et D.441-5 du Code de commerce.",
};

export const validationSettingsSchema = Yup.object({
  isChangelogNotificationEnabled: Yup.boolean(),
  isNewDealNotificationEnabled: Yup.boolean(),
  isMarketingNotificationEnabled: Yup.boolean(),
  timeZone: Yup.string(),
  isVHREnabled: Yup.boolean(),
  pricePerKm: Yup.number().min(
    0,
    'Price per km must be greater than or equal to 0',
  ),
});
