import { Typography } from 'components/Typography';
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { ProgressBar } from 'components';

import { OnboardingLayout } from 'layouts/OnboardingLayout/OnboardingLayout';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

type RedirectProps = {
  status: 'success' | 'error';
};

export const Redirect = (props: RedirectProps) => {
  const { status } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((state) => state + 1);
    }, 4000 / 100);

    const timer = setTimeout(() => {
      clearInterval(intervalId);
      const urlParams = new URLSearchParams(window.location.search);
      const redirectURI = urlParams.get('redirectURI');
      if (redirectURI) {
        window.location.assign(redirectURI);
      } else {
        navigate(PATHS.DASHBOARD);
      }
    }, 4000);
    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <OnboardingLayout>
      <div className="flex flex-col space-y-8">
        <Typography
          variant="h1"
          className="text-lg leading-6 font-semibold text-klaq-600"
        >
          Klaq.io
        </Typography>

        <Typography
          variant="h2"
          className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900"
        >
          {intl.formatMessage({
            id: `redirect.${status}.title`,
          })}
        </Typography>
        <Typography
          variant="body"
          className="mt-2 text-sm leading-6 text-gray-500"
        >
          {intl.formatMessage({
            id: `redirect.${status}.description`,
          })}
        </Typography>
        {status === 'success' ? (
          <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-green-100">
            <CheckIcon
              className="h-12 w-12 text-green-600"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-danger-100">
            <ExclamationTriangleIcon
              className="h-12 w-12 text-danger-600"
              aria-hidden="true"
            />
          </div>
        )}
        <ProgressBar progress={progress} color="klaq" />
      </div>
    </OnboardingLayout>
  );
};
