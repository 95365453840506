import { PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CardContainer, Typography, BillingDocumentList } from 'components';
import { MainEvent } from 'interface/event/main-event.interface';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';

export const BillingSection = (event: MainEvent) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleGenerateQuote = () => {
    navigate(`${PATHS.QUOTE_GENERATE}?fromEventId=${event.id}`);
  };

  const handleGenerateInvoice = () => {
    navigate(`${PATHS.INVOICE_GENERATE}?fromEventId=${event.id}`);
  };

  return (
    <div className="flex flex-col space-y-4 w-full">
      <CardContainer className="p-4 w-full flex flex-col space-y-4">
        <Typography
          variant="subtitle"
          className="flex justify-between items-center"
        >
          <Typography
            variant="subtitle"
            className="text-gray-900 font-semibold"
          >
            {intl.formatMessage({
              id: 'quote.header',
            })}
          </Typography>
          <button onClick={handleGenerateQuote}>
            <PlusCircleIcon className="h-8 w-8 text-klaq-500 hover:text-klaq-600" />
          </button>
        </Typography>
        {event.quotes && event.quotes.length ? (
          <BillingDocumentList documents={event.quotes} type="quote" />
        ) : (
          <div>
            <button
              onClick={handleGenerateQuote}
              type="button"
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-klaq-500 focus:ring-offset-2"
            >
              <PlusIcon
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              />

              <Typography
                variant="h3"
                className="mt-2 text-sm font-semibold text-gray-900"
              >
                {intl.formatMessage({
                  id: 'event-details.billing.no-quotes.header',
                })}
              </Typography>
              <Typography variant="body" className="mt-1 text-sm text-gray-500">
                {intl.formatMessage({
                  id: 'event-details.billing.no-quotes.description',
                })}
              </Typography>
            </button>
          </div>
        )}
      </CardContainer>

      <CardContainer className="p-4 w-full flex flex-col space-y-4">
        <Typography
          variant="subtitle"
          className="flex justify-between items-center"
        >
          <Typography
            variant="subtitle"
            className="text-gray-900 font-semibold"
          >
            {intl.formatMessage({
              id: 'invoices.header',
            })}
          </Typography>
          <button onClick={handleGenerateInvoice}>
            <PlusCircleIcon className="h-8 w-8 text-klaq-500 hover:text-klaq-600" />
          </button>
        </Typography>
        {event.invoices && event.invoices.length ? (
          <BillingDocumentList documents={event.invoices} type="invoice" />
        ) : (
          <div>
            <button
              onClick={handleGenerateInvoice}
              type="button"
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-klaq-500 focus:ring-offset-2"
            >
              <PlusIcon
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              />

              <Typography
                variant="h3"
                className="mt-2 text-sm font-semibold text-gray-900"
              >
                {intl.formatMessage({
                  id: 'event-details.billing.no-invoices.header',
                })}
              </Typography>
              <Typography variant="body" className="mt-1 text-sm text-gray-500">
                {intl.formatMessage({
                  id: 'event-details.billing.no-invoices.description',
                })}
              </Typography>
            </button>
          </div>
        )}
      </CardContainer>
    </div>
  );
};
