import { useAsyncCallback } from '@react-hooks-library/core';
import { Coordinates } from 'interface/map/coordinates.interface';
import { AddressSuggestions } from '../../interface/map/address-suggestion.interface';
import { RetrieveAddress } from '../../interface/map/retrieve-address.interface';
import webClient from '../../utils/webclient';

export const useGetAutocompleteSuggestions = () => {
  return useAsyncCallback(
    async (query: string, session_token): Promise<AddressSuggestions> => {
      try {
        const { data } = await webClient.get(
          `map/suggestions?query=${query}&session_token=${session_token}`,
        );
        return data;
      } catch (error: any) {
        console.error(error);
        return error.response;
      }
    },
  );
};

export const useRetrieveAddress = () => {
  return useAsyncCallback(
    async (
      mapboxId: string,
      session_token: string,
    ): Promise<RetrieveAddress> => {
      try {
        const { data } = await webClient.get(
          `map/retrieve?id=${mapboxId}&session_token=${session_token}`,
        );
        return data;
      } catch (error: any) {
        console.error(error);
        return error.response;
      }
    },
  );
};

export const useFetchDirections = () => {
  return useAsyncCallback(async (coordinates: Coordinates[]): Promise<any> => {
    try {
      const { data } = await webClient.post('map/directions', {
        coordinates,
      });
      return data;
    } catch (error: any) {
      console.error(error);
      return error.response;
    }
  });
};
