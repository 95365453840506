import { CustomerPortalLayout } from 'layouts';
import { Typography } from 'components/Typography';

export const CustomerPortal = () => {
  return (
    <CustomerPortalLayout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <Typography variant="h1">Dashboard</Typography>
        </div>
      </div>
    </CustomerPortalLayout>
  );
};
