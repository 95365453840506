import { Dialog, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MapPinIcon,
  PhoneIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { DangerModal, MailPopUp } from 'components';
import { differenceInDays, differenceInHours, format } from 'date-fns';
import { MainEvent } from 'interface/event/main-event.interface';
import { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { EventStatus } from 'enum/event-status.enum';
import {
  useFetchMainEvents,
  useUpdateArchivedStatus,
  useUpdateMainEventStatus,
} from 'redux/MainEvent/hooks';
import { PATHS } from 'routes';
import { useNavigate } from 'react-router-dom';

type NewEnquiryPopUpProps = {
  event: MainEvent;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

enum DEAL_WARMNESS {
  HOT,
  WARM,
  COLD,
}

export const NewEnquiryPopUp = (props: NewEnquiryPopUpProps) => {
  const { event, isOpen, setOpen } = props;
  const intl = useIntl();
  const [shouldOpenDelete, setOpenDelete] = useState<boolean>(false);
  const [isMailPopUpOpened, setMailPopupOpen] = useState<boolean>(false);
  const [, updateEventStatus] = useUpdateMainEventStatus();
  const eventCreationDate = new Date(event.createdAt);
  const navigate = useNavigate();
  const [, updateArchivedStatus] = useUpdateArchivedStatus();
  const [, fetchEvents] = useFetchMainEvents();

  const handleDeleteEvent = async () => {
    setOpenDelete(false);
    updateArchivedStatus(event.id, true);
    await fetchEvents();
    navigate(PATHS.EVENTS);
  };
  const handleAcceptEvent = () => {
    updateEventStatus(
      {
        status: EventStatus.QUALIFICATION,
      },
      event.id,
    );
  };

  const getDealWarmness = () => {
    const now = new Date();
    const hoursDifference = differenceInHours(now, eventCreationDate);
    const daysDifference = differenceInDays(now, eventCreationDate);

    if (hoursDifference < 24) {
      return DEAL_WARMNESS.HOT;
    }
    if (daysDifference < 7) {
      return DEAL_WARMNESS.WARM;
    }
    return DEAL_WARMNESS.COLD;
  };

  return (
    <>
      {
        //event.status === EventStatus.INBOX && (
        <>
          <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={setOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-999 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-50 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationTriangleIcon
                            className="h-6 w-6 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            {`${intl.formatMessage(
                              {
                                id: 'event-details.assistant.new-enquiry.infobox.title',
                              },
                              { date: eventCreationDate.toLocaleDateString() },
                            )} ${getDealWarmness() === DEAL_WARMNESS.HOT ? '🔥🔥🔥' : getDealWarmness() === DEAL_WARMNESS.WARM ? '🔥' : '❄️'} `}
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-black-500 mr-24">
                              <ul>
                                <li className="flex">
                                  <UserCircleIcon className="w-5 h-5 mr-2 text-gray-400" />
                                  {event.customer.firstName}
                                  {event.customer.lastName}
                                </li>
                                <li className="flex">
                                  <PhoneIcon className="w-5 h-5 mr-2 text-gray-400" />
                                  <a href="tel:${enquiry.phone}">
                                    {event.customer.phone}
                                  </a>
                                </li>
                                <li className="flex">
                                  <EnvelopeIcon className="w-5 h-5 mr-2 text-gray-400" />{' '}
                                  {event.customer.email}
                                </li>
                              </ul>
                              <div className="text-base font-bold my-2">
                                Informations sur l'événement :
                              </div>
                              <ul>
                                <li className="flex">
                                  <CalendarIcon className="w-5 h-5 mr-2 text-gray-400" />{' '}
                                  {format(
                                    new Date(event.subEvents[0].date),
                                    'dd/MM/yyyy',
                                  )}
                                </li>
                                <li className="flex">
                                  <MapPinIcon className="w-5 h-5 mr-2 text-gray-400" />
                                  {event.subEvents[0].address},{' '}
                                  {event.subEvents[0].zipcode},{' '}
                                  {event.subEvents[0].city},{' '}
                                  {event.subEvents[0].country}
                                </li>
                                <li className="flex">
                                  <SparklesIcon className="w-5 h-5 mr-2 text-gray-400" />
                                  {event.title}
                                </li>
                                <li className="flex">
                                  <UserGroupIcon className="w-5 h-5 mr-2 text-gray-400" />
                                  {event.subEvents[0].guests}
                                </li>
                                {event.note ? (
                                  <li className="flex">
                                    <div className="flex items-start justify-center">
                                      <InformationCircleIcon className="w-5 h-5 mr-2 text-gray-400" />
                                    </div>
                                    <div className="">{event.note}</div>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-success-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-success-500 sm:ml-3 sm:w-auto"
                          onClick={() => {
                            setOpen(false);
                            handleAcceptEvent();
                          }}
                        >
                          {intl.formatMessage({
                            id: 'event-details.assistant.new-enquiry.infobox.button.accept',
                          })}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            setOpen(false);
                            setMailPopupOpen(true);
                          }}
                        >
                          {intl.formatMessage({
                            id: 'event-details.assistant.new-enquiry.infobox.button.reject',
                          })}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <DangerModal
            isOpen={shouldOpenDelete}
            setOpen={setOpenDelete}
            onClick={() => handleDeleteEvent()}
            title={intl.formatMessage({
              id: 'event-details.delete-modal.title',
            })}
            message={intl.formatMessage({
              id: 'event-details.delete-modal.message',
            })}
            button1={intl.formatMessage({
              id: 'event-details.delete-modal.button.delete',
            })}
            button2={intl.formatMessage({
              id: 'event-details.delete-modal.button.cancel',
            })}
          />
          <MailPopUp
            isOpen={isMailPopUpOpened}
            setOpen={setMailPopupOpen}
            content={{
              to: event.customer.email,
              message: intl.formatMessage({
                id: 'email.template.unavailable.content',
              }),
              subject: intl.formatMessage({
                id: 'email.template.unavailable.subject',
              }),
            }}
            actionAfter={() => setOpenDelete(true)}
          />
        </>
        /*)*/
      }
    </>
  );
};
